import React, { useEffect, useState, Component } from 'react'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// import getCsrfTokenn from "../components/setCSRF.js"
import BoxAndWhiskerPlot from "../components/barandwhisker.js"
import YieldChart from "../components/barchart.js"
import Dropdown from 'react-bootstrap/Dropdown'
import IndexChart from "../components/indexchart.js"
import '../styles/mystyles.css'
import LogoBanner from "../components/logobanner.png"


const Terms = () => { 

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
        	<Helmet>
            	<title>Retire Smart Calc - Terms Of Service</title>
            	<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/terms"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />

				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			</Helmet>


        	<div className="row">
			<div className="col-sm-2"></div>
          	<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
			{/* <h1 className="display-5">Retire Smart Calc</h1> */}
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			<p className="lead"></p>
			{/* <hr className="my-4"></hr> */}


			<p className="lead">Terms of Service:</p>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>Welcome to RetireSmartCalc ("the Service"). These Terms of Service ("Terms") govern the user’s access to and use of the Service. By accessing or using the Service, you agree to be bound by these Terms.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>1. Use of the Service: The Service does not provide financial advice and is intended for educational and informational purposes only. The user agrees to use the Service only for lawful purposes and in accordance with these Terms. You are solely responsible for your use of the Service and you do so at your sole risk.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>2. Disclaimer of warranties: The Service makes no guarantees or warranties of any kind, express or implied, regarding the reliability, accuracy, completeness, or suitability of the information provided on the Service for any purpose.
			The Service does not guarantee that the information provided will meet the user's needs or expectations. Users are solely responsible for evaluating the suitability of the information for their individual circumstances.
			The Service may contain errors, inaccuracies, calculation mistakes or omissions. There is no warranty that the Service will be error-free or uninterrupted. Users acknowledge that they use the Service at their own risk and discretion.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>3. Access to and availability of the Service: We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part of it) with or without notice.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>4. Personal and non-commercial use limitation: The Service is intended for personal and non-commercial use only. Users are granted access to the Service solely for educational and informational purposes. You may not copy, reproduce, modify, distribute, transmit, display, perform, create derivative works from, transfer, or sell any information, analysis or services obtained from the Service.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>5. Intellectual property: All content on the Service, including text, graphics, logos, images, audio clips, video clips, data compilations, and software, is the property of RetireSmartCalc or its licensors and is protected by copyright, trademark, and other intellectual property laws.
			You may not copy, reproduce, modify, distribute, transmit, display, perform, create derivative works from, transfer, or sell any information, analysis or services obtained from the Service.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>6. Privacy & your data in the Service: This Service does not store or share any data that users create within the Service.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>7. Third-party links: The Service may contain links to third-party websites or resources that are not owned or controlled by RetireSmartCalc. We have no control over the content, privacy policies, or practices of any third-party websites or resources.
			You acknowledge and agree that RetireSmartCalc is not responsible or liable for any content, advertising, products, or other materials on or available from such websites or resources.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>8. Limitation of liability: In no event shall RetireSmartCalc, its directors, officers, employees, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the Service.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>9. Indemnification: You agree to indemnify and hold harmless RetireSmartCalc, its directors, officers, employees, and affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to legal fees) arising out of or in connection with your use of the Service.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>10. Changes to terms: RetireSmartCalc reserves the right to modify or amend these Terms at any time without prior notice. Your continued use of the Service after any changes to these Terms constitutes your acceptance of the revised Terms.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>11. Governing law: These Terms shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law principles.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
			</p>
			<p className={isSmallScreen ? 'small' : ''}>contact@retiresmartcalc.com
			</p>
			</div> 
			<div className="col-sm-2"></div>
		</div>
		</Container>
	); 
	}; 

	export default Terms;
	 
	  
	  
	  
	  