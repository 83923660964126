// import { create } from 'zustand';
// import { mountStoreDevtool } from 'simple-zustand-devtools';

// const useAuthStore = create((set, get) => ({
//     allUserData: null, // Use this to store all user data
//     loading: false,
//     user: () => ({
//         user_id: get().allUserData?.user_id || null,
//         username: get().allUserData?.username || null,
//     }),
//     setUser: (user) => set({ allUserData: user }),
//     setLoading: (loading) => set({ loading }),
//     isLoggedIn: () => get().allUserData !== null,
// }));

// // this is for VITE...
// // if (import.meta.env.DEV) {
// //     mountStoreDevtool('Store', useAuthStore);
// // }

// if (process.env.NODE_ENV === 'development') {
//     mountStoreDevtool('Store', useAuthStore);
// }

// export { useAuthStore };


import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

const useAuthStore = create((set, get) => ({
    allUserData: null, // Store all user data
    csrfToken: null,    // Store CSRF token
    accessToken: null,  // Store access token
    refreshToken: null, // Store refresh token
    loading: false,     // Loading state

    // Methods to retrieve user, CSRF token, and tokens
    user: () => ({
        user_id: get().allUserData?.user_id || null,
        username: get().allUserData?.username || null,
    }),
    getCSRFToken: () => get().csrfToken,      // Method to get the current CSRF token
    getAccessToken: () => get().accessToken,  // Method to get the access token
    getRefreshToken: () => get().refreshToken, // Method to get the refresh token

    // Methods to set user, CSRF token, access token, and refresh token
    setUser: (user) => set({ allUserData: user }),
    setCSRFToken: (token) => set({ csrfToken: token }),       // Set CSRF token
    setAccessToken: (token) => set({ accessToken: token }),   // Set access token
    setRefreshToken: (token) => set({ refreshToken: token }), // Set refresh token
    setLoading: (loading) => set({ loading }),

    // Methods to reset CSRF token, access token, refresh token, and user
    resetCSRFToken: () => set({ csrfToken: null }),           // Reset CSRF token
    resetAccessToken: () => set({ accessToken: null }),       // Reset access token
    resetRefreshToken: () => set({ refreshToken: null }),     // Reset refresh token
    resetUser: () => set({ allUserData: null }),

    // Check if the user is logged in
    isLoggedIn: () => get().allUserData !== null,
}));

// Optional: Devtools for debugging in development environment
if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('AuthStore', useAuthStore);
}

export { useAuthStore };