import { useAuthStore } from '../store/auth';
import axios from './axios';
// import { jwtDecode as jwt_decode } from 'jwt-decode';
// import Cookies from 'js-cookie';
// import { useSharedData } from '../components/setdata.js';
import { setAuthUser, setAuthUserToStore } from '../utils/auth'

function useLogin() {
    const { setCSRFToken, setAccessToken, setRefreshToken } = useAuthStore();

    // Define the login function inside the hook
    const login = async (username, password) => {
        try {
            const { data, status, headers } = await axios.post('login/', {
                username,
                password,
            });
            if (status === 200) {
                // setAuthUser(data.access, data.refresh);
                setAuthUserToStore(data.access);
                // console.log(data);
                // setAuthUser(data.access);
                // setCSRFToken(headers["x-csrftoken"]);
                // console.log('csrf token received in header at login', headers["x-csrftoken"])
                setAccessToken(data.access);
                // setRefreshToken(data.refresh);
            }
            return { data, error: null };
        } catch (error) {
            return {
                data: null,
                error: error.response.data?.detail || 'Something went wrong',
            };
        }
    };

    return { login }; // Return the function from the custom hook
}

export default useLogin;