import React from 'react'; 
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; 
import { HelmetProvider, Helmet } from 'react-helmet-async';
import About from './pages/home'; 
import Methodology from './pages/methodology';
import Learning from './pages/learning'; 
import Returns from './pages/returns';
import WithdrawalStrategies from './pages/withdrawalstrategies' 
import Terms from './pages/terms'; 
import Navigbar from './components/Navbar'; 
import Calculator from './pages/calculator.js';
// new....
import Home from './views/home';
import MainWrapper from './layouts/MainWrapper';
import Login from './views/login';
import PrivateRoute from './layouts/PrivateRoute';
import Logout from './views/logout';
import Private from './views/private';
import Register from './views/register';
import RetrievePlan from './components/retrieveplan'
// import { BrowserRouter as Switch } from 'react-router-dom';
import ResetPassword from './components/resetpassword'; // Adjust the import path as needed

function App() { 

	const helmetContext = {};

  // not completely sure what MainWrapper does, its connected with Zustand global state management??
  return ( 
    <HelmetProvider context={helmetContext}>
    <Router> 
      <MainWrapper>
      <Navigbar /> 
      <Routes> 
        <Route path='/' element={<About />}></Route>
        <Route path='/calc' element={<Calculator />}></Route>
        <Route path='/observations' element={<Learning />}></Route>
        <Route path='/strategies' element={<WithdrawalStrategies />}></Route>
        <Route path='/returns' element={<Returns />}></Route>
        <Route path='/methodology' element={<Methodology />}></Route>
        <Route path='/terms' element={<Terms />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
        {/* <Route path="/private" element={<PrivateRoute><RetrievePlan /></PrivateRoute>}/> */}
      </Routes>
      </MainWrapper>
    </Router> 
    </HelmetProvider>
  ); 
} 

export default App;

// import './App.css';
// import { Route, Routes, BrowserRouter } from 'react-router-dom';
// import Home from './views/home';
// import MainWrapper from './layouts/MainWrapper';
// import Login from './views/login';
// import PrivateRoute from './layouts/PrivateRoute';
// import Logout from './views/logout';
// import Private from './views/private';
// import Register from './views/register';

// function App() {
//     return (
//         <BrowserRouter>
//             <MainWrapper>
//                 <Routes>
//                     <Route
//                         path="/private"
//                         element={
//                             <PrivateRoute>
//                                 <Private />
//                             </PrivateRoute>
//                         }
//                     />
//                     <Route path="/" element={<Home />} />
//                     <Route path="/login" element={<Login />} />
//                     <Route path="/register" element={<Register />} />
//                     <Route path="/logout" element={<Logout />} />
//                 </Routes>
//             </MainWrapper>
//         </BrowserRouter>
//     );
// }

// export default App;