import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import '../styles/mystyles.css'

function DistChart(props) {

  // function isMobileDevice() {
  //   return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  // };

  // function setAspectRatio() {
  //   var aspectRatio = isMobileDevice() ? false : true; // Adjust the values as needed text
  //   return aspectRatio;
  // };
  
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * 0.3; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);


  const labels = props.data2

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'SWR',
        data: props.data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ],
  };

  const options = {
    responsive: true,
    // maintainAspectRatio: setAspectRatio(),
    // maintainAspectRatio: isLargeScreen,
    // seems to be you need maintainAspectRatio to true to handle small screen, but then keeping it true stops it resizing properly as screen goes big
    maintainAspectRatio: false,
    plugins: {
    legend: {
      display: false,
      },
      title: {
        display: true,
        font: {
          size: 12, // Set the font size for the title
        },
        text: 'Safe initial funding level ($,£ current value) by back-test cycle for constant withdrawal strategy',
        color: 'white',
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: 'First year of back-testing history',
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: labels,
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        beginAtZero: true,
        ticks: {
          color: 'white',
          beginAtZero: true,
          callback: function(value, index, ticks) {
            if (value >= 10000000 || value <= -10000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'} 
            else if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm'}
            else if (value >= 1000 || value <= -1000) {return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k'}
            else {return '' + value.toString()};
          },},
        // ticks: {
        //   color: 'white', // Set x-axis ticks color to white
        //   beginAtZero: true,
        //   callback: function(value, index, ticks) {
        //       return (value) + '$,£'
        //   },
        //   },
      },
    },
  };

  return (
    // <div style = {{ width:'100%', height:'300px'}}>
    //   <Line data={chartData} options={options} />
    // </div>

  <div>
    {isSmallScreen ? (
      <div style={{ width: '100%', height: '300px' }}>
        <Line data={chartData} options={options} />
      </div>
    ) : (
      <div style={{ width: '100%', height: `${chartHeight}px` }}>
        <Line data={chartData} options={options} />
      </div>
    )}
    <br></br>
    {/* <p className="small">Notice: maximum annual withdrawal does not include effect of adding state pension or fixed annuity to the portfolio</p> */}
  </div>
  );
};

export default DistChart;


