import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import useReset from '../utils/reset';
import { useAuthStore } from '../store/auth';

const ResetForm = ({ onClose }) => {
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message

    const resetForm = () => {
        setUsername('');
        setErrorMessage(''); // Reset the error message
    };

    const { reset } = useReset();

    const handleReset = async (e) => {
        e.preventDefault();
        const { error } = await reset(username);
        if (error) {
            setErrorMessage(error); // Set the error message to be displayed
        } else {
            resetForm();
            onClose(); // Close the modal on successful login
        }
    };

    return (
        <section>

            <Form onSubmit={handleReset}>
                <Form.Group>
                    <Form.Label htmlFor="userName" className="small">Username:</Form.Label>
                    <Form.Control 
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>
                {errorMessage && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        {errorMessage}
                    </div>
                )}
                <br />
                <Button variant="success" type="submit">
                    Send reset request
                </Button>
            </Form>

        </section>
    );
};

export default ResetForm;


