import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 

function DistChart(props) {

  // function isMobileDevice() {
  //   return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  // };

  // function setAspectRatio() {
  //   var aspectRatio = isMobileDevice() ? false : true; // Adjust the values as needed text
  //   return aspectRatio;
  // };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  const [title, setTitle] = useState();

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * 0.3; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    // props.data3 === 0 ? setTitle('Development of annual withdrawal value through each back-test simulation cycle (current $,£ net of tax)') : setTitle('Development of annual withdrawal value (including annuity income) through each back-test simulation cycle (current $,£ net of tax)');
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  useEffect(() => {
    props.data3 === 0 ? setTitle('Development of annual income through each back-test simulation cycle (current $,£ net of tax)') : setTitle('Development of annual income through each back-test simulation cycle (current $,£ net of tax)');
  }, [props.data3]);

  // const labels = Array.from({ length: props.data2 }, (_, index) => `${index + 1}`);
  const labels = props.data5;

  
  const datasets = props.data.map((dataArray, datasetIndex) => ({
    label: `Scenario ${datasetIndex + 1}`,
    data: dataArray,
    borderColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.2)`,
    backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.2)`,
  }));

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    // maintainAspectRatio: setAspectRatio(),
    // maintainAspectRatio: isLargeScreen,
    maintainAspectRatio: false,
    plugins: {
    legend: {
      display: false,
      },
      title: {
        display: true,
        font: {
          size: 14, // Set the font size for the title
        },
        // text: 'Development of annual withdrawal value through each back-test simulation cycle (current $,£ net of tax)',
        text: title,
        color: 'white',
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: 'In year beginning at age',
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: labels,
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
          beginAtZero: true,
          callback: function(value, index, ticks) {
            if (value >= 10000000 || value <= -10000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'} 
            else if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm'}
            else if (value >= 1000 || value <= -1000) {return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k'}
            else {return '' + value.toString()};
          },
        },

        // ticks: {
        //   beginAtZero: true,
        //   callback: function(value, index, ticks) {
        //     if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'} 
        //     else if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm'}
        //     else if (value >= 1000 || value <= -1000) {return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k'}
        //     else {return '' + value.toString()};
        //   },},
      },
    },
  };

  return (
    // <div style = {{ width:'100%', height:'300px'}}>
    //   <Line data={chartData} options={options} />
    // </div>

    <div>
    {isSmallScreen ? (
      <div style={{ width: '100%', height: '300px' }}>
        <Line data={chartData} options={options} />
      </div>
    ) : (
      <div style={{ width: '100%', height: `${chartHeight}px` }}>
        <Line data={chartData} options={options} />
      </div>
    )}
    </div>


  );
};

export default DistChart;


