import React, { useEffect, useState, useRef, Component } from 'react'
import useAxios from '../utils/useAxios';
import ValidateForm from "../components/validation.js"
import ValidateFormOptimisation from "../components/validationforoptimisation.js"
import ValidatePortfolioForm from "../components/validation_portfolio.js"
import ValidateStatePensionForm from "../components/validation_statepension.js"
import ValidateOccupationalPensionForm from "../components/validation_occupationalpension.js"
import ValidateContributionForm from "../components/validation_contribution.js"
import ValidateExtraContributionForm from "../components/validation_extracontribution.js"
import ValidateAnnuityForm from "../components/validation_annuity.js"
import ValidateIncomeForm from "../components/validation_income.js"
import ValidateFlexIncomeForm from "../components/validation_flexincome.js"
import MaxMinPlotChart from "../components/maxminchart.js"
import DistChart from "../components/distchart.js"
import DistValueFanChart from "../components/portfoliovaluefanchart.js"
import DrawDistChart from "../components/drawdistchart.js"
import DrawFanChart from "../components/drawdistfanchart.js"
import DistTypeChart from "../components/drawbytypedistchart.js"
import DrawHistChart from "../components/drawhistchart.js"
import BestSWRChart from "../components/bestswrchart.js"
import SafeFundingChart from "../components/safefundingchart.js"
import SafeFundingLevelChart from "../components/safefundinglevelchart.js"
import validatesanserrorsForm from "../components/validationsanserrors.js"
import PortfolioBreakdownPopup from "../components/portfoliobreakdownpopup.js"
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ButtonExample from "../components/loadingbutton.js";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import '../styles/mystyles.css'
import { Helmet } from 'react-helmet-async';
import LogoBanner from "../components/logobanner.png"
import '../App.css' // Import the CSS file
import TradeOffAnalysis from "../components/tradeoffanalysis.js"
import { useAuthStore } from '../store/auth'
import { usePresetStore } from "../components/datastore.js"; // Import the Zustand store
import { useFetcher } from 'react-router-dom';
import ReLoginForm from '../views/relogin';

function CalcDash1() {

    const presetObject = usePresetStore((state) => state.presetObject);
    const setPresetObject = usePresetStore((state) => state.setPresetObject);
    const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
    const planId = usePresetStore((state) => state.planId);
    const setPlanId = usePresetStore((state) => state.setPlanId);
    const resetPlanId = usePresetStore((state) => state.resetPlanId);

    const logInState = useAuthStore().isLoggedIn();
    const userId = useAuthStore().user();



    const api = useAxios();  // Ensure this is at the top level of your component or function

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    useEffect(() => {
      const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
      };
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
    }, []);

    const webpageData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Retire Smart Calc",
      "logo": "https://www.retiresmartcalc.com/logo.png",
      "description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
    };

    const API_HOST = 'http://127.0.0.1:8000';
    let _csrfToken = null
    const [yearList, setYearList] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [optrun, setOptrun] = useState(false);
    const [errors, setErrors] = useState({});

    const [result, setResult] = useState(0.0);
    const [decresult, setDecresult] = useState([0,0,0,0,0,0,0,0,0,0,0]);
    const [allresult, setAllresult] = useState([]);
    const [allresultGA, setAllresultGA] = useState([]);
    const [allresultTE, setAllresultTE] = useState([]);
    const [allresultTD, setAllresultTD] = useState([]);
    const [portfoliovaluepercentiles, setPortfoliovaluepercentiles] = useState([]);
    const [incomepercentiles, setIncomepercentiles] = useState([]);
    const [alldrawstreams, setAlldrawstreams] = useState([]);
    const [drawstreamsbytype, setDrawstreamsbytype] = useState([]);
    const [averagedraw, setAveragedraw] = useState(0.0);
    const [averageadjusteddraw, setAverageadjusteddraw] = useState(0.0);
    const [bestswrs, setBestswrs] = useState([]);
    const [safefunding, setSafefunding] = useState([]);
    const [safefundinglevel, setSafefundinglevel] = useState([]);
    const [resultyears, setResultyears] = useState([]);
    const [yearsForChart, setYearsForChart] = useState([]);
    const [yearsForChartShort, setYearsForChartShort] = useState([]);
    const [drawhistdata, setDrawhistdata] = useState({
      histogram: [],
      bins: [],
    });
    const [portfolioTotal,  setPortfolioTotal] = useState();
    const [portfolioActualAssetMmix, setPortfolioActualAssetMmix] = useState();
    const [sumdrawadjustedavg, setSumdrawadjustedavg] = useState(0.0)
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [buttonlabel, setButtonlabel] = useState('Calculating...')

    const [switchvalue, setSwitchvalue] = useState(1)
    const handleSwitchChange = (val) => setSwitchvalue(val);
    const [switchvalue2, setSwitchvalue2] = useState(1)
    const handleSwitchChange2 = (val) => setSwitchvalue2(val);
    const [switchvalue3, setSwitchvalue3] = useState(1)
    const handleSwitchChange3 = (val) => setSwitchvalue3(val);    
    const [switchvalue4, setSwitchvalue4] = useState(1)
    const handleSwitchChange4 = (val) => setSwitchvalue4(val); 

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {setIsModalOpen(true)};
    const closeModal = () => {setIsModalOpen(false)};

    const [isReLoginModalOpen, setIsReLoginModalOpen] = useState(false);
    const openReLoginModal = () => setIsReLoginModalOpen(true);
    const closeReLoginModal = () => setIsReLoginModalOpen(false);

    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
    const openAssetModal = () => {setIsAssetModalOpen(true)};
    const closeAssetModal = () => {setIsAssetModalOpen(false)};

    const [isStateModalOpen, setIsStateModalOpen] = useState(false);
    const openStateModal = () => {setIsStateModalOpen(true)};
    const closeStateModal = () => {setIsStateModalOpen(false)};

    const [isOccupationalModalOpen, setIsOccupationalModalOpen] = useState(false);
    const openOccupationalModal = () => {setIsOccupationalModalOpen(true)};
    const closeOccupationalModal = () => {setIsOccupationalModalOpen(false)};   

    const [isContributionModalOpen, setIsContributionModalOpen] = useState(false);
    const openContributionModal = () => {setIsContributionModalOpen(true)};
    const closeContributionModal = () => {setIsContributionModalOpen(false)}; 

    const [isExtraordinaryModalOpen, setIsExtraordinaryModalOpen] = useState(false);
    const openExtraordinaryModal = () => {setIsExtraordinaryModalOpen(true)};
    const closeExtraordinaryModal = () => {setIsExtraordinaryModalOpen(false)};  

    const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
    const openIncomeModal = () => {setIsIncomeModalOpen(true)};
    const closeIncomeModal = () => {setIsIncomeModalOpen(false)};  

    const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
    const openBonusModal = () => {setIsBonusModalOpen(true)};
    const closeBonusModal = () => {setIsBonusModalOpen(false)};  
    
    const [isAnnuityModalOpen, setIsAnnuityModalOpen] = useState(false);
    const openAnnuityModal = () => {setIsAnnuityModalOpen(true)};
    const closeAnnuityModal = () => {setIsAnnuityModalOpen(false)};  

    const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
    const openPortfolioModal = () => {setIsPortfolioModalOpen(true)};
    const closePortfolioModal = () => {setIsPortfolioModalOpen(false)};

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [modalResetMessage, setModalResetMessage] = useState('Are you sure you want to reset the data? This action cannot be undone.');
    const openResetModal = () => {setIsResetModalOpen(true)};
    const closeResetModal = () => {setIsResetModalOpen(false)};
    
    const [modalmessage, setModalmessage] = useState();

    const [inPortfolioEquity, setInPortfolioEquity] = useState({});
    const [inPortfolioBonds, setInPortfolioBonds] = useState({});
    const [inPortfolioIndex, setInPortfolioIndex] = useState({});
    const [inPortfolioTotal, setInPortfolioTotal] = useState({});

    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioType, setPortfolioType] = useState('0');
    const [portfolioAmount, setPortfolioAmount] = useState('');
    const [portfolioEquity, setPortfolioEquity] = useState('');
    const [portfolioBonds, setPortfolioBonds] = useState('');
    const [portfolioIndex, setPortfolioIndex] = useState('');
    const [plans, setPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState(null); // State for selected plan ID for deletion

    const [isRetrieveModalOpen, setIsRetrieveModalOpen] = useState(false);
    const openRetrieveModal = () => {setIsRetrieveModalOpen(true)};
    const closeRetrieveModal = () => {setIsRetrieveModalOpen(false)};

    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal visibility

    const getPlans = async () => {
      try {
        const response = await api.get('/getplans/');
        const data = response.data;
        setPlans(data.plans);
        openRetrieveModal();
      } catch (error) {
        // setModalmessage('Error. Action could not be completed. If problem persists, try logging user out and in again.');
        // openModal();
        // handleShowLogin();
        openReLoginModal();
      }
    };

    const handleOpenDeleteModal = (planIdNumber) => {
      setSelectedPlanId(planIdNumber); // Set the planId to delete
      setShowDeleteModal(true); // Show the confirmation modal
    };
  
    // Function to close the delete confirmation modal
    const handleCloseDeleteModal = () => {
      setSelectedPlanId(null); // Clear selected plan ID
      setShowDeleteModal(false); // Close the confirmation modal
    };

    const deletePlan = async (planIdNumber) => {
      try {
        // Send DELETE request as a POST request with planIdNumber in the body
        const response = await api.post('/deleteplan/', { plan_id: planIdNumber });
        // Update plans by removing the deleted plan
        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== planIdNumber));
        // Reset the state if the deleted plan was the currently active one
        if (planIdNumber === planId) {
          resetPlanId();
          resetPresetObject();
        }
      } catch (error) {
        // setModalmessage('Error. Action could not be completed. If problem persists, try logging user out and in again.');
        // openModal();       
        openReLoginModal();
        // if (error.response) {
        //   console.error('Error deleting plan:', error.response.data.error);
        // } else {
        //   console.error('Unexpected error:', error);
        // }
      }
    
      // Close the confirmation modal after deletion
      handleCloseDeleteModal();
    };

    const retrievePlan = async (planIdNumber) => {
        try {
          const response = await api.post('/retrieve/', { plan_id: planIdNumber });
          const data = response.data;
          if (response.status === 200) {  
            {setPresetObject({ 
              ['plan_title']: data.plan_title,
              ['data_start_year']: data.data_start_year,
              ['data_end_year']: data.data_end_year,
              ['currency_set']: data.currency_set,
              ['geographic_set']: data.geographic_set,
              ['equity_tax']: data.equity_tax,
              ['bond_tax']: data.bond_tax,
              ['draw_tax']: data.draw_tax,
              ['bond_coupon']: data.bond_coupon,
              ['index_bond_coupon']: data.index_bond_coupon,
              ['asset_mix_equity']: data.asset_mix_equity,
              ['asset_mix_bond']: data.asset_mix_bond,
              ['asset_mix_index_bond']: data.asset_mix_index_bond,
              ['asset_mix_source']: data.asset_mix_source,
              ['data_option']: data.data_option,
              ['data_direction']: data.data_direction,
              ['dynamic_option']: data.dynamic_option,
              ['withdrawal_percent']: data.withdrawal_percent,
              ['target_withdrawal_percent']: data.target_withdrawal_percent,
              ['net_other_income']: data.net_other_income,
              ['yale_weighting']: data.yale_weighting,
              ['vanguard_decrease_floor']: data.vanguard_decrease_floor,
              ['vanguard_increase_ceiling']: data.vanguard_increase_ceiling,
              ['annuity_tax_rate']: data.annuity_tax_rate,
              ['annuity_tax_rate2']: data.annuity_tax_rate2,
              ['annuity_tax_rate3']: data.annuity_tax_rate3,
              ['fees']: data.fees,
              ['spread']: data.spread,
              ['equity_alpha']: data.equity_alpha,
              ['fixed_income_alpha']: data.fixed_income_alpha,
              ['start_simulation_age']: data.start_simulation_age,
              ['end_simulation_age']: data.end_simulation_age,
              ['circular_simulation']: data.circular_simulation,
              ['extraordinary_inflows']: data.extraordinary_inflows,
              ['state_pension']: data.state_pension,
              ['occupational_pension']: data.occupational_pension,
              ['annuity_pension']: data.annuity_pension,
              ['contribution_inflows']: data.contribution_inflows,
              ['income_requirements']: data.income_requirements,
              ['flex_income_requirements']: data.flex_income_requirements,
              ['asset_portfolio']: data.asset_portfolio,
              ['apply_tax_to_inflation']: data.apply_tax_to_inflation,
            })};
            setPlanId(planIdNumber);
            closeRetrieveModal();
            // setModalmessage('Portfolio data retrieved.');
            // openModal()
          };
           } catch (error) {
            openReLoginModal();
            // setModalmessage('Error. Action could not be completed. If problem persists, try logging user out and in again.');
            // openModal();   
            // if (error.response && error.response.status === 401) {
            //   setModalmessage('Session expired. Please log in again.');
            //   // Optional: Trigger login modal or redirect to login page
            //   setShowLoginModal(true);  // Assuming you use a modal
            // } else {
            //   // Generic error message for other errors
            //   setModalmessage('Error retrieving plan: ' + error.message);
            // }
            // openModal();
          }
        };

        const checkNumberPlans = async () => {
          try {
            const response = await api.get('/getplans/'); // Fetch the list of plans
            const data = response.data;
            const response2 = await api.get('/userinformation/');  // Fetch the user's permitted number of plans
            const permittedNumberOfPlans = response2.data
            const numberOfPlans = data.plans.length;
            if (numberOfPlans < permittedNumberOfPlans.userinformation.number_plans) {return true} else {return false}
          } catch (error) {
            console.error('Error fetching plans:', error);
            return 0; // In case of an error, return 0 (or handle this case as you see fit)
          }
        };

        const savePlan = async () => { 
          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              const requestData = {
                ...presetObject,
                ...(planId && { planId })  // Include planId if it's not null or undefined
              };
              const response = await api.post('/save/', requestData);
        
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved.');
                openModal();
              }}
            } catch (error) {
              openReLoginModal();
              // setModalmessage('Error. Action could not be completed. If problem persists, try logging user out and in again.');
              // openModal();   
              // if (error.response) {
              //   setModalmessage('Error saving portfolio data: ' + (error.response.data.message || 'Unknown error'));
              // } else if (error.request) {
              //   setModalmessage('No response from the server.');
              // } else {
              //   setModalmessage('Error: ' + error.message);
              // }
              // openModal();
            }
          }
        };

    const resetPlan = () => {
      resetPresetObject();
      resetPlanId();
      closeResetModal();
    };

    const handlePortfolioChange = () => {
      var portfolioweightsum = parseInt(portfolioEquity) + parseInt(portfolioBonds) + parseInt(portfolioIndex)
      if (ValidatePortfolioForm(portfolioName, portfolioAmount, portfolioEquity, portfolioBonds, portfolioIndex, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({asset_portfolio: [...presetObject.asset_portfolio, {name: portfolioName, type: portfolioType, amount: portfolioAmount, equity: portfolioEquity, bonds: portfolioBonds, index: portfolioIndex}]});
        setPortfolioName('');
        setPortfolioType('0');
        setPortfolioAmount('');
        setPortfolioEquity('');
        setPortfolioBonds('');
        setPortfolioIndex('');
      };
      closeAssetModal();
    };    
    
    const [extraName, setExtraName] = useState('');
    const [extraYear, setExtraYear] = useState('');
    const [extraAmount, setExtraAmount] = useState('');
    const [extraIndex, setExtraIndex] = useState('1');
    const [extraIncrease, setExtraIncrease] = useState('0');
    const [extraType, setExtraType] = useState('0');

    const handleExtraInflowChange = () => {
      if (ValidateExtraContributionForm(presetObject, extraName, extraYear, extraAmount, extraIncrease, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({extraordinary_inflows: [...presetObject.extraordinary_inflows, {name: extraName, year: extraYear, amount: extraAmount, index: extraIndex, increase: extraIncrease, type: extraType}]});
        setExtraName('');
        setExtraYear('');
        setExtraAmount('');
        setExtraIndex('1');
        setExtraIncrease(0);
        setExtraType('0');
      };
      closeExtraordinaryModal();
    };

    const [stateName, setStateName] = useState('');
    const [stateYear, setStateYear] = useState('');
    const [stateAmount, setStateAmount] = useState('');
    const [stateIndex, setStateIndex] = useState('1');
    const [stateIncrease, setStateIncrease] = useState('0');

    const handleStatePensionChange = () => {
      if (ValidateStatePensionForm(presetObject, stateName, stateYear, stateAmount, stateIncrease, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({state_pension: [...presetObject.state_pension, {name: stateName, year: stateYear, amount: stateAmount, index: stateIndex, increase: stateIncrease}]});
        setStateName('');
        setStateYear('');
        setStateAmount('');
        setStateIndex('1');
        setStateIncrease(0);
        closeStateModal();
      };
    };

    const [occupationalName, setOccupationalName] = useState('');
    const [occupationalYear, setOccupationalYear] = useState('');
    const [occupationalAmount, setOccupationalAmount] = useState('');
    const [occupationalIndex, setOccupationalIndex] = useState('1');
    const [occupationalIncrease, setOccupationalIncrease] = useState('0');

    const handleOccupationalPensionChange = () => {
        if (ValidateOccupationalPensionForm(presetObject, occupationalName, occupationalYear, occupationalAmount, occupationalIncrease, setErrors, setIsModalOpen, setModalmessage)) {
          setPresetObject({occupational_pension: [...presetObject.occupational_pension, {name: occupationalName, year: occupationalYear, amount: occupationalAmount, index: occupationalIndex, increase: occupationalIncrease}]});
          setOccupationalName('');
          setOccupationalYear('');
          setOccupationalAmount('');
          setOccupationalIndex('1');
          setOccupationalIncrease(0);
          closeOccupationalModal();
      };
    };  

    const [annuityName, setAnnuityName] = useState('');
    const [annuityYear, setAnnuityYear] = useState('');
    const [annuityAmount, setAnnuityAmount] = useState('');
    const [annuityPrice, setAnnuityPrice] = useState('');
    const [annuityIndex, setAnnuityIndex] = useState('1');
    const [annuityIncrease, setAnnuityIncrease] = useState('0');

    const handleAnnuityPensionChange = () => {
      if (ValidateAnnuityForm(presetObject, annuityName, annuityYear, annuityAmount, annuityPrice, annuityIncrease, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({annuity_pension: [...presetObject.annuity_pension, {name: annuityName, year: annuityYear, amount: annuityAmount, price: annuityPrice, index: annuityIndex, increase: annuityIncrease}]});
        setAnnuityName('');
        setAnnuityYear('');
        setAnnuityAmount('');
        setAnnuityPrice('');
        setAnnuityIndex('1');
        setAnnuityIncrease(0);
        closeAnnuityModal();
      };
    };

    const [contributionName, setContributionName] = useState('');
    const [contributionYear, setContributionYear] = useState('');
    const [contributionYearEnd, setContributionYearEnd] = useState('');
    const [contributionAmount, setContributionAmount] = useState('');
    const [contributionIndex, setContributionIndex] = useState('1');
    const [contributionIncrease, setContributionIncrease] = useState('0');
    const [contributionType, setContributionType] = useState('0');

    const handleContributionChange = () => {
        if (ValidateContributionForm(presetObject, contributionName, contributionYear, contributionYearEnd, contributionAmount, contributionIncrease, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({contribution_inflows: [...presetObject.contribution_inflows, {name: contributionName, year: contributionYear, yearend: contributionYearEnd, amount: contributionAmount, index: contributionIndex, increase: contributionIncrease, type: contributionType}]});
        setContributionName('');
        setContributionYear('');
        setContributionYearEnd('');
        setContributionAmount('');
        setContributionIndex('1');
        setContributionIncrease(0);
        setContributionType('0');
        closeContributionModal();
      };
    };  

    const [incomeName, setIncomeName] = useState('');
    const [incomeYear, setIncomeYear] = useState('');
    const [incomeYearEnd, setIncomeYearEnd] = useState('');
    const [incomeAmount, setIncomeAmount] = useState('');
    const [incomeIndex, setIncomeIndex] = useState('1');
    const [incomeIncrease, setIncomeIncrease] = useState('0');
    const [incomeIncrease2, setIncomeIncrease2] = useState('0');

    const handleIncomeChange = () => {
        if (ValidateIncomeForm(presetObject, incomeName, incomeYear, incomeYearEnd, incomeAmount, incomeIncrease, incomeIncrease2, setErrors, setIsModalOpen, setModalmessage)) { 
        if (incomeIndex === '0' || incomeIndex === '1') {setPresetObject({income_requirements: [...presetObject.income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease}]})} else {setPresetObject({...presetObject, income_requirements: [...presetObject.income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease2}]})};
        setIncomeName('');
        setIncomeYear('');
        setIncomeYearEnd('');
        setIncomeAmount('');
        setIncomeIndex('1');
        setIncomeIncrease(0);
        setIncomeIncrease2(0);
        closeIncomeModal();
      };
    }; 

    const [flexIncomeName, setFlexIncomeName] = useState('');
    const [flexIncomeYear, setFlexIncomeYear] = useState('');
    const [flexIncomeYearEnd, setFlexIncomeYearEnd] = useState('');
    const [flexIncomeAmount, setFlexIncomeAmount] = useState('');
    const [flexIncomeIndex, setFlexIncomeIndex] = useState('1');
    const [flexIncomeIncrease, setFlexIncomeIncrease] = useState('0');

    const handleFlexIncomeChange = () => {
        if (ValidateFlexIncomeForm(presetObject, flexIncomeName, flexIncomeYear, flexIncomeYearEnd, flexIncomeAmount, flexIncomeIncrease, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({flex_income_requirements: [...presetObject.flex_income_requirements, {name: flexIncomeName, year: flexIncomeYear, yearend: flexIncomeYearEnd, amount: flexIncomeAmount, index: flexIncomeIndex, increase: flexIncomeIncrease}]});
        setFlexIncomeName('');
        setFlexIncomeYear('');
        setFlexIncomeYearEnd('');
        setFlexIncomeAmount('');
        setFlexIncomeIndex('1');
        setFlexIncomeIncrease(0);
        closeBonusModal();
      };
    }; 

    const handlePortfolioDeleteItem = (index) => {
      setPresetObject({
        asset_portfolio: presetObject.asset_portfolio.filter((_, i) => i !== index),
      });
    };

    const handlePortfolioModityItem = (index) => {
      const item = presetObject.asset_portfolio[index];
      setPortfolioName(item.name);
      setPortfolioType(item.type); 
      setPortfolioAmount(item.amount);
      setPortfolioEquity(item.equity);
      setPortfolioBonds(item.bonds);
      setPortfolioIndex(item.index);
      setPresetObject({
        asset_portfolio: presetObject.asset_portfolio.filter((_, i) => i !== index),
      });
      openAssetModal();
    };   

    const handleExtraDeleteItem = (index) => {
      setPresetObject({
        extraordinary_inflows: presetObject.extraordinary_inflows.filter((_, i) => i !== index),
      });
    };

    const handleExtraModityItem = (index) => {
      const item = presetObject.extraordinary_inflows[index];
      setExtraName(item.name);
      setExtraYear(item.year); 
      setExtraAmount(item.amount);
      setExtraIndex(item.index);
      setExtraIncrease(item.increase);
      setExtraType(item.type);
      setPresetObject({
        extraordinary_inflows: presetObject.extraordinary_inflows.filter((_, i) => i !== index),
      });
      openExtraordinaryModal();
    };

    const handleStatePensionDeleteItem = (index) => {
      setPresetObject({
        state_pension: presetObject.state_pension.filter((_, i) => i !== index),
      });
    };

    const handleStatePensionModityItem = (index) => {
      const item = presetObject.state_pension[index];
      setStateName(item.name);
      setStateYear(item.year); 
      setStateAmount(item.amount);
      setStateIndex(item.index);
      setStateIncrease(item.increase);
      setPresetObject({
        state_pension: presetObject.state_pension.filter((_, i) => i !== index),
      });
      openStateModal();
    };

    const handleOccupationalPensionDeleteItem = (index) => {
      setPresetObject({
        occupational_pension: presetObject.occupational_pension.filter((_, i) => i !== index),
      });
    };

    const handleOccupationalPensionModityItem = (index) => {
      const item = presetObject.occupational_pension[index];
      setOccupationalName(item.name);
      setOccupationalYear(item.year); 
      setOccupationalAmount(item.amount);
      setOccupationalIndex(item.index);
      setOccupationalIncrease(item.increase);
      setPresetObject({
        occupational_pension: presetObject.occupational_pension.filter((_, i) => i !== index),
      });
      openOccupationalModal();
    };

    const handleAnnuityPensionDeleteItem = (index) => {
      setPresetObject({
        annuity_pension: presetObject.annuity_pension.filter((_, i) => i !== index),
      });
    };

    const handleAnnuityPensionModityItem = (index) => {
      const item = presetObject.annuity_pension[index];
      setAnnuityName(item.name);
      setAnnuityYear(item.year); 
      setAnnuityAmount(item.amount);
      setAnnuityPrice(item.price);
      setAnnuityIndex(item.index);
      setAnnuityIncrease(item.increase);
      setPresetObject({
        annuity_pension: presetObject.annuity_pension.filter((_, i) => i !== index),
      });
      openAnnuityModal();
    };

    const handleContributionDeleteItem = (index) => {
      setPresetObject({
        contribution_inflows: presetObject.contribution_inflows.filter((_, i) => i !== index),
      });
    };

    const handleContributionModityItem = (index) => {
      const item = presetObject.contribution_inflows[index];
      setContributionName(item.name);
      setContributionYear(item.year); 
      setContributionYearEnd(item.yearend);
      setContributionAmount(item.amount);
      setContributionIndex(item.index);
      setContributionIncrease(item.increase);
      setContributionType(item.type);
      setPresetObject({
        contribution_inflows: presetObject.contribution_inflows.filter((_, i) => i !== index),
      });
      openContributionModal();
    };

    const handleIncomeDeleteItem = (index) => {
      setPresetObject({
        income_requirements: presetObject.income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleIncomeModityItem = (index) => {
      const item = presetObject.income_requirements[index];
      setIncomeName(item.name);
      setIncomeYear(item.year); 
      setIncomeYearEnd(item.yearend);
      setIncomeAmount(item.amount);
      setIncomeIndex(item.index);
      setIncomeIncrease(item.increase);
      setIncomeIncrease2(item.increase2);
      setPresetObject({
        income_requirements: presetObject.income_requirements.filter((_, i) => i !== index),
      });
      openIncomeModal();
    };

    const handleFlexIncomeDeleteItem = (index) => {
      setPresetObject({
        flex_income_requirements: presetObject.flex_income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleFlexIncomeModityItem = (index) => {
      const item = presetObject.flex_income_requirements[index];
      setFlexIncomeName(item.name);
      setFlexIncomeYear(item.year); 
      setFlexIncomeYearEnd(item.yearend);
      setFlexIncomeAmount(item.amount);
      setFlexIncomeIndex(item.index);
      setFlexIncomeIncrease(item.increase);
      setPresetObject({
        flex_income_requirements: presetObject.flex_income_requirements.filter((_, i) => i !== index),
      });
      openBonusModal();
    };

    const handleObjectChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: parseFloat(value),})) {
        setPresetObject({[property]: parseFloat(value),});
      };
    };

    const handleTextChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: value,})) {
        setPresetObject({[property]: value,});
      };
    };

    const handleSelectChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const handleDataCalibrationChange = (property, value) => {
      if (value === "backUSDshiller") {setPresetObject({['data_start_year']: 1870, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "backUSDshort") {setPresetObject({['data_start_year']: 1925, ['data_end_year']: 1995, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'back', ['circular_simulation']: "0",[property]: value,})}
      else if (value === "backUSDglobal") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'GLOBAL', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "forwardUSD") {setPresetObject({['data_start_year']: 1870, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "forwardUSDglobal") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'GLOBAL', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "back") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'GBP', ['geographic_set']: 'GLOBAL', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'GBP', ['geographic_set']: 'GLOBAL', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})};    
    };

    const getSimulationData = async () => {
      let apiroute = ""
      apiroute = "getsimulationdata"
      try {
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'GET',
        headers: {'HTTP_API_KEY': 'testkeytestkey99'},
      })
      const data = await response.json();
      setPresetObject({['historic_asset_return_data']: data.historic_dataset, ['forward_asset_return_data']: data.forward_dataset, ['mortality_data']: data.mortality_dataset});
      } catch (error) {console.log(error)}
    }

    const handleSubmit = async () => {
      let apiroute = ""
      if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
      setLoading4(true);
      apiroute = "simulation"
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: {'HTTP_API_KEY': 'testkeytestkey99'},
        body: JSON.stringify(presetObject),
      })
      const data = await response.json();
      setPortfoliovaluepercentiles(data.portfolio_all_value_streams_percentiles);
      setIncomepercentiles(data.income_all_streams_percentiles);     
      setResult(data.simulation_fails * 100);
      setDecresult(data.portfolio_end_value_decile);
      setAllresult(data.portfolio_all_value_streams);
      setAllresultGA(data.portfolio_all_value_streams_ga);
      setAllresultTE(data.portfolio_all_value_streams_te);
      setAllresultTD(data.portfolio_all_value_streams_td);
      setAlldrawstreams(data.income_all_streams);
      setDrawstreamsbytype(data.median_income_by_type_all_cycles);
      setBestswrs(data.max_zero_fail_SWR_by_cycle);
      setAveragedraw(data.avg_income);
      setAverageadjusteddraw(data.avg_mort_adjusted_income);
      setSafefunding(data.max_SWR_by_simulation_year);
      setSafefundinglevel(data.safe_funding_level);
      setDrawhistdata({
        'histogram':data.income_histogram_data,
        'bins':data.income_histogram_edges,
      });
      setSumdrawadjustedavg(data.sum_mort_adjusted_discounted_income);
      setResultyears(data.simulation_years);
      setYearsForChart(data.years_for_chart);
      setPortfolioTotal(data.portfolio_total);
      setPortfolioActualAssetMmix(data.portfolio_actual_asset_mix);
      setYearsForChartShort(data.years_for_chart_short);

      if (presetObject.asset_mix_source === "0") {
        setPresetObject({
          asset_mix_equity: data.portfolio_actual_asset_mix[0] * 100,
          asset_mix_bond: data.portfolio_actual_asset_mix[1] * 100,
          asset_mix_index_bond: data.portfolio_actual_asset_mix[2] * 100,
        });
      }

      } catch (error) {console.log(error)} finally {setLoading4(false)}

    };
    };

    const changeOptimalMixMax = async () => {
      let apiroute = ""
      if (ValidateFormOptimisation(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
        setLoading2(true);
        setPresetObject({['asset_mix_source']: "1"});
        apiroute = "mixopt";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {'HTTP_API_KEY': 'testkeytestkey99'},
          body: JSON.stringify(presetObject),
        });
        const data = await response.json();
        setPresetObject({
          ['asset_mix_equity']: data.optimised_equity_max,
          ['asset_mix_bond']: data.optimised_bond_max,
          ['asset_mix_index_bond']: data.optimised_index_bond_max,
        });
        setOptrun(!optrun);
        if (data.optimised_failure_max > 0) {
          setModalmessage('Asset mix optimised to give lowest possible but >0% failure rate. Review portfolio starting value and/or withdrawal level.');
          openModal()} else {setModalmessage('Asset mix updated.'); openModal()};
        } catch (error) {console.log(error)}
        finally {setLoading2(false)} 
      };
    };
    const changeOptimalMixMin = async () => {
      let apiroute = ""
      if (ValidateFormOptimisation(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
        setLoading3(true);
        setPresetObject({['asset_mix_source']: "1"});
        apiroute = "mixopt";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {'HTTP_API_KEY': 'testkeytestkey99'},
          body: JSON.stringify(presetObject),
        });
        const data = await response.json();
        setPresetObject({
          ['asset_mix_equity']: data.optimised_equity_min, 
          ['asset_mix_bond']: data.optimised_bond_min, 
          ['asset_mix_index_bond']: data.optimised_index_bond_min,});
        setOptrun(!optrun);
        if (data.optimised_failure_min > 0) {
          setModalmessage('Asset mix optimised to give lowest possible but >0% failure rate. Review portfolio starting value and/or withdrawal level.');
          openModal()} else {setModalmessage('Asset mix updated.'); openModal()};
        } catch (error) {console.log(error)} 
        finally {setLoading3(false)} 
      };
    };

    const runMixMaxOptimisationAndReCalc = async () => {
      await changeOptimalMixMax();
    }

    const runMixMinOptimisationAndReCalc = async () => {
      await changeOptimalMixMin();
    }
    
    useEffect(() => {
      getSimulationData();
    }, []);

    useEffect(() => {
      if (isInitialRender) {setIsInitialRender(false)} else {handleSubmit()}
    }, [optrun]);

    useEffect(() => {
      if (presetObject.data_direction == 'back' && presetObject.asset_mix_index_bond > 0) {
        {setModalmessage('No allocation to index bonds possible with historic backtesting data selected. Existing index linked bond allocation moved to conventional bond allocation.'); openModal()};
      setPresetObject({['asset_mix_bond']: parseFloat(presetObject.asset_mix_index_bond + presetObject.asset_mix_bond), ['asset_mix_index_bond']: parseFloat(0),});
    }}, [presetObject.data_direction]);

    useEffect(() => {
      setYearList(Array.from({ length: presetObject.data_end_year - presetObject.data_start_year + 1}, (_, index) => presetObject.data_start_year + index));
    }, [presetObject.data_option]);

    useEffect(() => {
      var eq_general = 0.0 
      var eq_taxexempt = 0.0
      var eq_taxdeferred = 0.0
      var bd_general = 0.0;
      var bd_taxexempt = 0.0;
      var bd_taxdeferred = 0.0;
      var ix_general = 0.0;
      var ix_taxexempt = 0.0;
      var ix_taxdeferred = 0.0;
      var total = 0.0;
      var eq_general_percent = 0
      var bd_general_percent = 0
      var ix_general_percent = 0
      var eq_taxexempt_percent = 0
      var bd_taxexempt_percent = 0
      var ix_taxexempt_percent = 0
      var eq_taxdeferred_percent = 0
      var bd_taxdeferred_percent = 0
      var ix_taxdeferred_percent = 0
      var general_percent = 0
      var taxexempt_percent = 0 
      var taxdeferred_percent = 0
      var eq_percent = 0
      var bd_percent = 0
      var ix_percent = 0
      var total_percent = 0
      var total_general = 0
      var total_taxexempt = 0
      var total_taxdeferred = 0
      var total_equity = 0
      var total_bonds = 0
      var total_index = 0

      for (let i = 0; i < presetObject.asset_portfolio.length; i++) {
        if (presetObject.asset_portfolio[i].type === "0") 
        {
        eq_general = eq_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_general = bd_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_general = ix_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        else if (presetObject.asset_portfolio[i].type === "1")
        {
        eq_taxexempt = eq_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_taxexempt = bd_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_taxexempt = ix_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        else
        {
        eq_taxdeferred = eq_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_taxdeferred = bd_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_taxdeferred = ix_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        total = total + parseFloat(presetObject.asset_portfolio[i].amount)
      };
      total_general = eq_general + bd_general + ix_general
      total_taxexempt = eq_taxexempt + bd_taxexempt + ix_taxexempt
      total_taxdeferred = eq_taxdeferred + bd_taxdeferred + ix_taxdeferred
      total_equity = eq_general + eq_taxexempt + eq_taxdeferred
      total_bonds = bd_general + bd_taxexempt + bd_taxdeferred
      total_index = ix_general + ix_taxexempt + ix_taxdeferred
      if (total !== 0) {
        eq_general_percent = eq_general / total * 100
        bd_general_percent = bd_general / total * 100
        ix_general_percent = ix_general / total * 100
        eq_taxexempt_percent = eq_taxexempt / total * 100
        bd_taxexempt_percent = bd_taxexempt / total * 100
        ix_taxexempt_percent = ix_taxexempt / total * 100
        eq_taxdeferred_percent = eq_taxdeferred / total * 100
        bd_taxdeferred_percent = bd_taxdeferred / total * 100
        ix_taxdeferred_percent = ix_taxdeferred / total * 100
        general_percent = total_general / total * 100
        taxexempt_percent = total_taxexempt / total * 100
        taxdeferred_percent = total_taxdeferred / total * 100
        eq_percent = total_equity / total * 100
        bd_percent = total_bonds / total * 100
        ix_percent = total_index / total * 100
        total_percent = total / total * 100
      } else
      {
        eq_general_percent = 0
        bd_general_percent = 0
        ix_general_percent = 0
        eq_taxexempt_percent = 0
        bd_taxexempt_percent = 0
        ix_taxexempt_percent = 0
        eq_taxdeferred_percent = 0
        bd_taxdeferred_percent = 0
        ix_taxdeferred_percent = 0
        general_percent = 0
        taxexempt_percent = 0 
        taxdeferred_percent = 0
        eq_percent = 0
        bd_percent = 0
        ix_percent = 0
        total_percent = 0
      }
      setInPortfolioEquity({['general'] : eq_general, ['tax_exempt'] : eq_taxexempt, ['tax_deferred'] : eq_taxdeferred, ['total'] : total_equity, ['general_percent'] : eq_general_percent, ['tax_exempt_percent'] : eq_taxexempt_percent, ['tax_deferred_percent'] : eq_taxdeferred_percent, ['total_percent'] : eq_percent});
      setInPortfolioBonds({['general'] : bd_general, ['tax_exempt'] : bd_taxexempt, ['tax_deferred'] : bd_taxdeferred, ['total'] : total_bonds, ['general_percent'] : bd_general_percent, ['tax_exempt_percent'] : bd_taxexempt_percent, ['tax_deferred_percent'] : bd_taxdeferred_percent, ['total_percent'] : bd_percent});
      setInPortfolioIndex({['general'] : ix_general, ['tax_exempt'] : ix_taxexempt, ['tax_deferred'] : ix_taxdeferred, ['total'] : total_index, ['general_percent'] : ix_general_percent, ['tax_exempt_percent'] : ix_taxexempt_percent, ['tax_deferred_percent'] : ix_taxdeferred_percent, ['total_percent'] : ix_percent});
      setInPortfolioTotal({['general'] : total_general, ['tax_exempt'] : total_taxexempt, ['tax_deferred'] : total_taxdeferred, ['total'] : total, ['general_percent'] : general_percent, ['tax_exempt_percent'] : taxexempt_percent, ['tax_deferred_percent'] : taxdeferred_percent, ['total_percent'] : total_percent});
      setPortfolioTotal(total);
    }, [presetObject.asset_portfolio])

    return (
      <Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
        	<Helmet>
            <title>Retire Smart Calc - Retirement Portfolio Simulation Calculator</title>
            <meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
            <link rel="canonical" href="https://www.retiresmartcalc.com/#/calc"></link>
            <link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
            <script type="application/ld+json">
            {JSON.stringify(webpageData)}
            </script>
          </Helmet>

        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
          {isSmallScreen ?
          <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> 
          :
          <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />
          }

          <div className="my-4"></div> 
          <hr className="my-2" />     

          <h6>User profile:</h6>
          <div className="my-2" /> 


          {logInState ? (
            <>
              <div className="my-2"></div>
              <span className="fw-light">User logged in: {userId.username} </span>
              <button className="btn btn-info me-1" onClick={getPlans}>
                Retrieve plan
              </button> 
              <button className="btn btn-info me-1" onClick={savePlan}>
                Save plan
              </button>
              <button className="btn btn-info" onClick={openResetModal}>
                Reset / create new plan
              </button>
              <div className="my-2"></div>
            </>
          ) : (
            <>
              <div className="my-2"></div>
              <span className="fw-light">User not logged in </span>
              <button className="btn btn-info" onClick={openResetModal}>
                Reset / create new plan
              </button>
              <div className="my-2"></div>
            </>
          )}


            <Row>
              <Form.Group>
                <Form.Label htmlFor="plan_title" className="fw-light">Plan title:</Form.Label>
                <Form.Control type="text" name="plan_title" id="plan_title" value={presetObject.plan_title} onChange={(e) => handleTextChange('plan_title', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
                {errors.plan_title && <Alert className="alert alert-danger">{errors.plan_title}</Alert>}
              </Form.Group>              
            </Row>

            <Row>
            <Form.Group as={Col}>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age at start of simulation (enter youngest age if for couple)</Tooltip>}>           
            <Form.Label htmlFor="start_simulation_age" className="fw-light">Age at start of simulation:</Form.Label>
            </OverlayTrigger>
            <Form.Control type="number" name="start_simulation_age" id="start_simulation_age" value={presetObject.start_simulation_age} onChange={(e) => handleObjectChange('start_simulation_age', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.start_simulation_age && <Alert className="alert alert-danger">{errors.start_simulation_age}</Alert>}
            </Form.Group>
            <Form.Group as={Col}>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age to run simulation up until e.g. if maximum planned life expectancy is 100 years, enter 100 years.</Tooltip>}>           
            <Form.Label htmlFor="end_simulation_age" className="fw-light">Age at end of simulation:</Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="end_simulation_age" 
              id="end_simulation_age" 
              value={presetObject.end_simulation_age} 
              onChange={(e) => handleObjectChange('end_simulation_age', e.target.value)} 
              className="form-control bg-secondary border-secondary text-light" 
              autoComplete="off">
            </Form.Control>
            {errors.end_simulation_age && <Alert className="alert alert-danger">{errors.end_simulation_age}</Alert>}
            </Form.Group>
            </Row>

            <hr className="my-2" />           
            <h6>Current retirement savings:</h6>
            <div className="my-2" />

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add asset to portfolio.</Tooltip>}>           
            <Accordion.Header>Add asset to portfolio
            <span className="badge-space">
            <Badge bg="info">{presetObject.asset_portfolio.length ? presetObject.asset_portfolio.length : <></>}</Badge>
            </span>
            </Accordion.Header>
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">

            <div className="my-2" />
            <Button variant="success" className="me-1" onClick={openAssetModal }>
            Add
            </Button>
            <Button variant="success" className="me-1" onClick={openPortfolioModal}>
            Show Portfolio Breakdown
            </Button>
            <PortfolioBreakdownPopup show={isPortfolioModalOpen} onClose={closePortfolioModal} data={portfolioTotal} data2={inPortfolioEquity} data3={inPortfolioBonds} data4={inPortfolioIndex} data5={inPortfolioTotal}/>
            <div className="my-2" />
     
            <Modal show={isAssetModalOpen} onHide={closeAssetModal}>
            <Modal.Header closeButton>
            <Modal.Title>Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="portfolioName" className="small">Description:</Form.Label>
                <Form.Control
                  name="portfolioName"
                  id="portfolioName"
                  className="form-control border-secondary"
                  type="text"
                  value={portfolioName}
                  onChange={(e) => setPortfolioName(e.target.value)}
                  placeholder="Name"         
                />
              {errors.portfolioName && <Alert className="alert alert-danger">{errors.portfolioName}</Alert>}
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="portfolioAmount" className="small">Amount ($,£):</Form.Label>
                <Form.Control
                  name="portfolioAmount"
                  id="portfolioAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={portfolioAmount}
                  onChange={(e) => setPortfolioAmount(e.target.value)}
                  placeholder="Amount"         
                />
              {errors.portfolioAmount && <Alert className="alert alert-danger">{errors.portfolioAmount}</Alert>}
            </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="portfolioType" className="small">Fund type:</Form.Label>
                <Form.Select
                  name="portfolioType"
                  id="portfolioType"
                  className="form-control border-secondary"
                  value={portfolioType}
                  onChange={(e) => setPortfolioType(e.target.value)}
                  defaultValue="0">
                  <option value="0">General Account</option>
                  <option value="1">Tax Exempt Savings Account</option>
                  <option value="2">Tax Deferred Pension Account</option>
                </Form.Select>
            </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="portfolioEquity" className="small">% Equity:</Form.Label>
                <Form.Control
                  name="portfolioEquity"
                  id="portfolioEquity"
                  className="form-control border-secondary"
                  type="number"
                  value={portfolioEquity}
                  onChange={(e) => setPortfolioEquity(e.target.value)}
                  placeholder="%"         
                />
              {errors.portfolioEquity && <Alert className="alert alert-danger">{errors.portfolioEquity}</Alert>}  
            </Form.Group>
              
            <Form.Group>
                <Form.Label htmlFor="portfolioBonds" className="small">% Conventional Bonds:</Form.Label>
                <Form.Control
                  name="portfolioBonds"
                  id="portfolioBonds"
                  className="form-control border-secondary"
                  type="number"
                  value={portfolioBonds}
                  onChange={(e) => setPortfolioBonds(e.target.value)}
                  placeholder="%"         
                />
              {errors.portfolioBonds && <Alert className="alert alert-danger">{errors.portfolioBonds}</Alert>}
            </Form.Group>
              
            <Form.Group>
                <Form.Label htmlFor="portfolioIndex" className="small">% Index Bonds:</Form.Label>
                <Form.Control
                  name="portfolioIndex"
                  id="portfolioIndex"
                  className="form-control border-secondary"
                  type="number"
                  value={portfolioIndex}
                  onChange={(e) => setPortfolioIndex(e.target.value)}
                  placeholder="%"         
                />
              {errors.portfolioIndex && <Alert className="alert alert-danger">{errors.portfolioIndex}</Alert>}
            </Form.Group>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handlePortfolioChange }>
            Enter
            </Button>
            </Modal.Footer>
            </Modal>

          <Row>
              {presetObject.asset_portfolio.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        {item.amount === null || item.amount === undefined ? <>''</> : <p className={isSmallScreen ? 'small' : ''}>Amount ($,£): {item.amount.toLocaleString()}</p>}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Type: {item.type === '0' ? 'General' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Equity: {item.equity}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Conventional Bonds: {item.bonds}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Index Bonds: {item.index}
                      </Card.Text>

                      <Button variant="danger" onClick={() => handlePortfolioDeleteItem(index)}>
                        Delete
                      </Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handlePortfolioModityItem(index)}>
                        Modify
                      </Button>                      
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            
            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>

            <div className="mt-2"></div>
            <div className="my-2" />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add state pension / social security income to portfolio.</Tooltip>}>           
            <Accordion.Header>(Optional) add state pension
            <span className="badge-space">
            <Badge bg="info">{presetObject.state_pension.length ? presetObject.state_pension.length : <></>}</Badge>
            </span>
            </Accordion.Header>
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">

            <Button className="btn btn-success" onClick={openStateModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isStateModalOpen} onHide={closeStateModal}>
            <Modal.Header closeButton>
            <Modal.Title>State Pension</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Row>
            <Form.Group as={Col}>
                <Form.Label htmlFor="stateName" className="small">Description:</Form.Label>
                <Form.Control
                  name="stateName"
                  id="stateName"
                  className="form-control border-secondary"
                  type="text"
                  value={stateName}
                  onChange={(e) => setStateName(e.target.value)}
                  placeholder="Name"         
                />
                {errors.stateName && <Alert className="alert alert-danger">{errors.stateName}</Alert>}
              </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col}>
                <Form.Label htmlFor="stateYear" className="small">Year (age):</Form.Label>
                <Form.Control
                  name="stateYear"
                  id="stateYear"
                  className="form-control border-secondary"
                  type="number"
                  value={stateYear}
                  onChange={(e) => setStateYear(e.target.value)}
                  placeholder="Year"
                  min="1"           
                />
                {errors.stateYear && <Alert className="alert alert-danger">{errors.stateYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="stateAmount" className="small">Amount (current):</Form.Label>
                <Form.Control
                  name="stateAmount"
                  id="stateAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={stateAmount}
                  onChange={(e) => setStateAmount(e.target.value)}
                  placeholder="Amount"
                  min="1"
                />
                {errors.stateAmount && <Alert className="alert alert-danger">{errors.stateAmount}</Alert>}
                </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col}>
                <Form.Label htmlFor="stateIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select
                  name="stateIndex"
                  id="stateIndex"
                  className="form-control border-secondary"
                  value={stateIndex}
                  onChange={(e) => setStateIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="stateIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control
                  name="stateIncrease"
                  id="stateIncrease"
                  className="form-control border-secondary"
                  type="number"
                  value={stateIncrease}
                  onChange={(e) => setStateIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.stateIncrease && <Alert className="alert alert-danger">{errors.stateIncrease}</Alert>}
                </Form.Group>
                </Row>

                </Modal.Body>
                <Modal.Footer>
            <Button variant="primary" onClick={handleStatePensionChange }>
            Enter
            </Button>
            </Modal.Footer>
            </Modal>


          <Row>
              {presetObject.state_pension.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Year (age): {item.year}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. over index (%): {item.increase}
                      </Card.Text>
                      <Button variant="danger" onClick={() => handleStatePensionDeleteItem(index)}>
                        {/* <i className="bi bi-trash"></i> */}
                        Delete
                      </Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handleStatePensionModityItem(index)}>
                        Modify
                      </Button>                      
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>

            <div className="my-2" />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add occupational pension income to portfolio.</Tooltip>}>           
            <Accordion.Header>(Optional) add occupational pension
            <span className="badge-space">
            <Badge bg="info">{presetObject.occupational_pension.length ? presetObject.occupational_pension.length : <></>}</Badge>
            </span>
            </Accordion.Header> 
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">

            <Button className="btn btn-success" onClick={openOccupationalModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isOccupationalModalOpen} onHide={closeOccupationalModal}>
            <Modal.Header closeButton>
            <Modal.Title>Occupational Pension</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Row>
            <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalName" className="small">Description:</Form.Label>
                <Form.Control
                  name="occupationalName"
                  id="occupationalName"
                  className="form-control border-secondary"
                  type="text"
                  value={occupationalName}
                  onChange={(e) => setOccupationalName(e.target.value)}
                  placeholder="Name"        
                />
                {errors.occupationalName && <Alert className="alert alert-danger">{errors.occupationalName}</Alert>}
              </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col}>
              {/* <InputGroup> */}
                <Form.Label htmlFor="occupationalYear" className="small">Year (age):</Form.Label>
                <Form.Control
                  name="occupationalYear"
                  id="occupationalYear"
                  className="form-control border-secondary"
                  type="number"
                  value={occupationalYear}
                  onChange={(e) => setOccupationalYear(e.target.value)}
                  placeholder="Year"
                  min="1"           
                />
                {errors.occupationalYear && <Alert className="alert alert-danger">{errors.occupationalYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalAmount" className="small">Amount (current):</Form.Label>
                <Form.Control
                  name="occupationalAmount"
                  id="occupationalAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={occupationalAmount}
                  onChange={(e) => setOccupationalAmount(e.target.value)}
                  placeholder="Amount"
                  min="1"
                />
                {errors.occupationalAmount && <Alert className="alert alert-danger">{errors.occupationalAmount}</Alert>}
                </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select
                  name="occupationalIndex"
                  id="occupationalIndex"
                  className="form-control border-secondary"
                  value={occupationalIndex}
                  onChange={(e) => setOccupationalIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label htmlFor="occupationalIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control
                  name="occupationalIndex"
                  id="occupationalIndex"
                  className="form-control border-secondary"
                  type="number"
                  value={occupationalIncrease}
                  onChange={(e) => setOccupationalIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.occupationalIncrease && <Alert className="alert alert-danger">{errors.occupationalIncrease}</Alert>}
                </Form.Group>
                </Row>
                </Modal.Body>
                <Modal.Footer>
            <Button variant="primary" onClick={handleOccupationalPensionChange }>
            Enter
            </Button>
            </Modal.Footer>
            </Modal>

            <Row>
              {presetObject.occupational_pension.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Year (age): {item.year}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. over index (%): {item.increase}
                      </Card.Text>
                      <Button variant="danger" onClick={() => handleOccupationalPensionDeleteItem(index)}>
                        Delete
                      </Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handleOccupationalPensionModityItem(index)}>
                        Modify
                      </Button>   
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>

            <hr className="my-2" />           
            <h6>Future retirement saving:</h6>
            <div className="my-2" />

            <div className="my-2" />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add contributions.</Tooltip>}>           
            <Accordion.Header>(Optional) add contributions
            <span className="badge-space">
            <Badge bg="info">{presetObject.contribution_inflows.length ? presetObject.contribution_inflows.length : <></>}</Badge>
            </span>
            </Accordion.Header>
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">


            <Button className="btn btn-success" onClick={openContributionModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isContributionModalOpen} onHide={closeContributionModal}>
            <Modal.Header closeButton>
            <Modal.Title>Contribution</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Row>
            <Form.Group as={Col}>
                <Form.Label htmlFor="contributionName" className="small">Description:</Form.Label>
                <Form.Control
                  name="contributionName"
                  id="contributionName"
                  className="form-control border-secondary"
                  type="text"
                  value={contributionName}
                  onChange={(e) => setContributionName(e.target.value)}
                  placeholder="Name"           
                />
              {errors.contributionName && <Alert className="alert alert-danger">{errors.contributionName}</Alert>}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label htmlFor="contributionYear" className="fw-light" className="small">Start year (age):</Form.Label>
                <Form.Control
                  name="contributionYear"
                  id="contributionYear"
                  className="form-control border-secondary"
                  type="number"
                  value={contributionYear}
                  onChange={(e) => setContributionYear(e.target.value)}
                  placeholder="AgeStart"
                  min="1"
                  />
              {errors.contributionYear && <Alert className="alert alert-danger">{errors.contributionYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="contributionYearEnd" className="fw-light" className="small">End year (age):</Form.Label>
                <Form.Control
                  name="contributionYearEnd"
                  id="contributionYearEnd"
                  className="form-control border-secondary"
                  type="number"
                  value={contributionYearEnd}
                  onChange={(e) => setContributionYearEnd(e.target.value)}
                  placeholder="AgeEnd"
                  min="1"           
                />
              {errors.contributionYearEnd && <Alert className="alert alert-danger">{errors.contributionYearEnd}</Alert>}
              </Form.Group>
              </Row>
              <Row>
              <Form.Group as={Col}>
                <Form.Label htmlFor="contributionAmount" className="fw-light" className="small">Amount (current):</Form.Label>
                <Form.Control
                  name="contributionAmount"
                  id="contributionAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={contributionAmount}
                  onChange={(e) => setContributionAmount(e.target.value)}
                  placeholder="Amount"
                  min="1"
                />
              {errors.contributionAmount && <Alert className="alert alert-danger">{errors.contributionAmount}</Alert>}
                </Form.Group>

                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionType" className="fw-light" className="small">Fund type:</Form.Label>
                <Form.Select
                  name="contributionType"
                  id="contributionType"
                  className="form-control border-secondary"
                  value={contributionType}
                  onChange={(e) => setContributionType(e.target.value)}
                  defaultValue="0">
                  <option value="0">General Account</option>
                  <option value="1">Tax Exempt Account</option>
                  <option value="2">Tax Deferred Account</option>
                </Form.Select>
                </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionIndex" className="fw-light" className="small">Index (Y/N):</Form.Label>
                <Form.Select
                  name="contributionIndex"
                  id="contributionIndex"
                  className="form-control border-secondary"
                  value={contributionIndex}
                  onChange={(e) => setContributionIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>

                <Form.Group as={Col}>
                <Form.Label htmlFor="contributionIncrease" className="fw-light" className="small">Inc. over index (%):</Form.Label>
                <Form.Control
                  name="contributionIncrease"
                  id="contributionIncrease"
                  className="form-control border-secondary"
                  type="number"
                  value={contributionIncrease}
                  onChange={(e) => setContributionIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.contributionIncrease && <Alert className="alert alert-danger">{errors.contributionIncrease}</Alert>}
                </Form.Group>
                </Row>
                {/* <Row>
                <Form.Group as={Col}>
                <div className="my-2" /> */}
                {/* <br></br> */}

                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleContributionChange }>
                Enter
                </Button>
                </Modal.Footer>
                </Modal>

            {/* </Form.Group>
            <div className="my-2" />
            </Row> */}

            <Row>
              {presetObject.contribution_inflows.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Start year (age): {item.year}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        End year (age): {item.yearend}
                      </Card.Text>                      
                      <Card.Text className="tight-spacing">
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Fund type: {item.type === '0' ? 'General Account' : item.type === '1' ? 'Tax Exempt Account' : 'Tax Deferred Account'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. over index (%): {item.increase}
                      </Card.Text>
                      <Button variant="danger" onClick={() => handleContributionDeleteItem(index)}>
                        Delete
                      </Button>
                      <span> </span>
                       <Button variant="info" onClick={() => handleContributionModityItem(index)}>
                      Modify
                      </Button>   
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>


            <div className="my-2" />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Option to add extraordinary / one-off inflows that will add to the portfolio value at a specified point through retirement simulation</Tooltip>}>           
            <Accordion.Header>(Optional) add extraordinary inflows
            <span className="badge-space">
            <Badge bg="info">{presetObject.extraordinary_inflows.length ? presetObject.extraordinary_inflows.length : <></>}</Badge>
            </span>
            </Accordion.Header>
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">

            <Button className="btn btn-success" onClick={openExtraordinaryModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isExtraordinaryModalOpen} onHide={closeExtraordinaryModal}>
            <Modal.Header closeButton>
            <Modal.Title>Extraordinary inflow</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="extraName" className="small">Description:</Form.Label>
                <Form.Control
                  name="extraName"
                  id="extraName"
                  className="form-control border-secondary"
                  type="text"
                  value={extraName}
                  onChange={(e) => setExtraName(e.target.value)}
                  placeholder="Name"       
                />
              {errors.extraName && <Alert className="alert alert-danger">{errors.extraName}</Alert>}
              </Form.Group>  
            <Form.Group>
              {/* <InputGroup> */}
                <Form.Label htmlFor="extraYear" className="fw-light" className="small">Year (age):</Form.Label>
                <Form.Control
                  name="extraYear"
                  id="extraYear"
                  className="form-control border-secondary"
                  type="number"
                  value={extraYear}
                  onChange={(e) => setExtraYear(e.target.value)}
                  placeholder="Year"
                  min="1"           
                />
              {errors.extraYear && <Alert className="alert alert-danger">{errors.extraYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="extraAmount" className="fw-light" className="small">Amount (current):</Form.Label>
                <Form.Control
                  name="extraAmount"
                  id="extraAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={extraAmount}
                  onChange={(e) => setExtraAmount(e.target.value)}
                  placeholder="Amount"
                  min="1"
                />
              {errors.extraAmount && <Alert className="alert alert-danger">{errors.extraAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraType" className="fw-light" className="small">Fund type:</Form.Label>
                <Form.Select
                  name="extraType"
                  id="extraType"
                  className="form-control border-secondary"
                  value={extraType}
                  onChange={(e) => setExtraType(e.target.value)}
                  defaultValue="1">
                  <option value="0">General Account</option>
                  <option value="1">Tax Exempt Account</option>
                  <option value="2">Tax Deferred Account</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraIndex" className="fw-light" className="small">Annual inc. (select):</Form.Label>
                <Form.Select
                  name="extraIndex"
                  id="extraIndex"
                  className="form-control border-secondary"
                  value={extraIndex}
                  onChange={(e) => setExtraIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="extraIncrease" className="fw-light" className="small">Inc. over index (%):</Form.Label>
                <Form.Control
                  name="extraIncrease"
                  id="extraIncrease"
                  className="form-control border-secondary"
                  type="number"
                  value={extraIncrease}
                  onChange={(e) => setExtraIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.extraIncrease && <Alert className="alert alert-danger">{errors.extraIncrease}</Alert>}                
                </Form.Group>
                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleExtraInflowChange }>
                Enter
                </Button>
                </Modal.Footer>
                </Modal>

            <Row>
              {presetObject.extraordinary_inflows.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Year (age): {item.year}
                      </Card.Text>                   
                      <Card.Text className="tight-spacing">
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Fund type: {item.type === '0' ? 'General Account' : item.type === '1' ? 'Tax Exempt Account' : 'Tax Deferred Account'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. over index (%): {item.increase}
                      </Card.Text>
                      <Button variant="danger" onClick={() => handleExtraDeleteItem(index)}>
                        Delete
                      </Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handleExtraModityItem(index)}>
                        Modify
                      </Button>                      

                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>

            <hr className="my-2" />           
            <h6>Retirement income requirements and withdrawal approach:</h6>
            <div className="my-2" />

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`} className={"custom-tooltip"}>- Constant: fixed withdrawal taken for income each year irrespective of market performance.<br />- Constant with bonus: as constant but additional withdrawal bonus paid if portfolio value above safe funding level.<br />- Fixed percentage withdrawal: withdrawal level calculated each year as a % of portfolio value to prevent portfolio being exhausted with option to add minimum withdrawal level.<br />- Yale Endownment: withdrawal = x * previous withdrawal + (1 - x) * % portfolio value<br />- Vanguard Dynamic Spending: % portfolio value with cap / floor on max real-term change from previous withdrawal<br />- Bogleheads Variable Percentage Withdrawal: % portfolio value taken from look up table adjusted for age and asset mix</Tooltip>}>           
            <Form.Label htmlFor="dynamic_option" className="fw-light">Select portfolio withdrawal approach:</Form.Label>
            </OverlayTrigger>

            <Form.Select 
              name="dynamic_option"
              id="dynamic_option"
              className="form-control bg-secondary border-secondary text-light"
              onChange={(e) => handleSelectChange('dynamic_option', e.target.value)}
              defaultValue="constant">
              <option value="constant" >Constant $</option>
              <option value="constantbonus">Constant $ with bonus (dynamic SWR)</option>
              {/* <option value="constantflex" >Flex $ (dynamic SWR)</option> */}
              <option value="proportional" >Fixed percentage withdrawal</option>
              <option value="yale" >Yale endownment rule</option>
              <option value="vanguard" >Vanguard dynamic spending rule</option>
              <option value="vpw" >Bogleheads variable percentage withdrawal</option>
		        </Form.Select>
            {presetObject.dynamic_option === 'constant' || presetObject.dynamic_option === 'constantbonus' ? (
              <>
              </>
              ) : presetObject.dynamic_option === 'yale' ? (
                <>
              <Row>
              <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annual income withdrawal rate (as % of portfolio value each year through simulation) (net of tax)</Tooltip>}>           
              <Form.Label htmlFor="target_withdrawal_percent" className="fw-light">Annual draw. rate (as % of port.):</Form.Label>
              </OverlayTrigger>    
              <Form.Control
                type="number"
                name="target_withdrawal_percent" 
                id="target_withdrawal_percent" 
                value={presetObject.target_withdrawal_percent} 
                onChange={(e) => handleObjectChange('target_withdrawal_percent', e.target.value)} 
                className="form-control bg-secondary border-secondary text-light" 
                autoComplete="off">
              </Form.Control>
              {errors.target_withdrawal_percent && <Alert className="alert alert-danger">{errors.target_withdrawal_percent}</Alert>}
              </Form.Group>

              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Sets whether other income (e.g. state pension, occupational pension, purchased annuity) is included in calculating withdrawal (total income) amount as a % portfolio value.</Tooltip>}>           
                <Form.Label htmlFor="net_other_income" className="fw-light">Include other income in withdrawal calc:</Form.Label>
                </OverlayTrigger>
                <Form.Select 
                name="net_other_income"
                id="net_other_income"
                className="form-control bg-secondary border-secondary text-light"
                onChange={(e) => handleSelectChange('net_other_income', e.target.value)}
                defaultValue="0">
                <option value="0" >Do not include</option>
                {/* <option value="1" >Include</option> */}
                </Form.Select>
              </Form.Group>
              {/* </Row>
              <Row> */}
              <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Income withdrawal = weighting x previous_year_withdrawal + (1 - weighting) * (withdrawal rate * portfolio value)</Tooltip>}>           
              <Form.Label htmlFor="yale_weighting" className="fw-light">Weight. of previous year's draw. (%):</Form.Label>
              </OverlayTrigger>    
              <Form.Control
                type="number"
                name="yale_weighting" 
                id="yale_weighting" 
                value={presetObject.yale_weighting} 
                onChange={(e) => handleObjectChange('yale_weighting', e.target.value)} 
                className="form-control bg-secondary border-secondary text-light" 
                autoComplete="off">
              </Form.Control>
              {errors.yale_weighting && <Alert className="alert alert-danger">{errors.yale_weighting}</Alert>}
              </Form.Group>
              </Row>
              </>
              ) : presetObject.dynamic_option === 'vanguard' ? (
              <>
              <Row>
              <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annual income withdrawal rate (as % of portfolio value each year through simulation) (net of tax)</Tooltip>}>           
              <Form.Label htmlFor="target_withdrawal_percent" className="fw-light">Annual draw. (as % of port.):</Form.Label>
              </OverlayTrigger>    
              <Form.Control
                type="number"
                name="target_withdrawal_percent" 
                id="target_withdrawal_percent" 
                value={presetObject.target_withdrawal_percent} 
                onChange={(e) => handleObjectChange('target_withdrawal_percent', e.target.value)} 
                className="form-control bg-secondary border-secondary text-light" 
                autoComplete="off">
              </Form.Control>
              {errors.target_withdrawal_percent && <Alert className="alert alert-danger">{errors.target_withdrawal_percent}</Alert>}
              </Form.Group>

              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Sets whether other income (e.g. state pension, occupational pension, purchased annuity) is included in calculating withdrawal (total income) amount as a % portfolio value.</Tooltip>}>           
                <Form.Label htmlFor="net_other_income" className="fw-light">Include other income in draw. calc:</Form.Label>
                </OverlayTrigger>
                <Form.Select 
                name="net_other_income"
                id="net_other_income"
                className="form-control bg-secondary border-secondary text-light"
                onChange={(e) => handleSelectChange('net_other_income', e.target.value)}
                defaultValue="0">
                <option value="0" >Do not include</option>
                {/* <option value="1" >Include</option> */}
                </Form.Select>
              </Form.Group>
              </Row>
              <Row>
              <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Maximum annual real-term reduction (%)</Tooltip>}>           
              <Form.Label htmlFor="vanguard_decrease_floor" className="fw-light">Maximum annual real-term reduction (%):</Form.Label>
              </OverlayTrigger>    
              <Form.Control
                type="number"
                name="vanguard_decrease_floor" 
                id="vanguard_decrease_floor" 
                value={presetObject.vanguard_decrease_floor} 
                onChange={(e) => handleObjectChange('vanguard_decrease_floor', e.target.value)} 
                className="form-control bg-secondary border-secondary text-light" 
                autoComplete="off">
              </Form.Control>
              {errors.vanguard_decrease_floor && <Alert className="alert alert-danger">{errors.vanguard_decrease_floor}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Maximum annual real-term increase (%)</Tooltip>}>           
              <Form.Label htmlFor="vanguard_increase_ceiling" className="fw-light">Maximum annual real-term increase (%):</Form.Label>
              </OverlayTrigger>    
              <Form.Control
                type="number"
                name="vanguard_increase_ceiling" 
                id="vanguard_increase_ceiling" 
                value={presetObject.vanguard_increase_ceiling} 
                onChange={(e) => handleObjectChange('vanguard_increase_ceiling', e.target.value)} 
                className="form-control bg-secondary border-secondary text-light" 
                autoComplete="off">
              </Form.Control>
              {errors.vanguard_increase_ceiling && <Alert className="alert alert-danger">{errors.vanguard_increase_ceiling}</Alert>}
              </Form.Group>
              </Row>
              </>
              ) : presetObject.dynamic_option === 'vpw' ? (<></>)
              : (
              <>
              <Row>
              <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annual total income withdrawal (as % of portfolio value each year through simulation) (net of tax).</Tooltip>}>           
              <Form.Label htmlFor="target_withdrawal_percent" className="fw-light">Annual withdrawal (as % of portfolio):</Form.Label>
              </OverlayTrigger>    
              <Form.Control
                type="number"
                name="target_withdrawal_percent" 
                id="target_withdrawal_percent" 
                value={presetObject.target_withdrawal_percent} 
                onChange={(e) => handleObjectChange('target_withdrawal_percent', e.target.value)} 
                className="form-control bg-secondary border-secondary text-light" 
                autoComplete="off">
              </Form.Control>
              {errors.target_withdrawal_percent && <Alert className="alert alert-danger">{errors.target_withdrawal_percent}</Alert>}
              </Form.Group>

              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Sets whether other income (e.g. state pension, occupational pension, purchased annuity) is included in calculating withdrawal (total income) amount as a % portfolio value.</Tooltip>}>           
                <Form.Label htmlFor="net_other_income" className="fw-light">Include other income in withdrawal calc:</Form.Label>
                </OverlayTrigger>
                <Form.Select 
                name="net_other_income"
                id="net_other_income"
                className="form-control bg-secondary border-secondary text-light"
                onChange={(e) => handleSelectChange('net_other_income', e.target.value)}
                defaultValue="0">
                <option value="0" >Do not include</option>
                <option value="1" >Include</option>
                </Form.Select>
              </Form.Group>

              </Row>
              </>  
              )}

            <div className="mt-2"></div>

            {presetObject.dynamic_option !== 'vpw' ? 
            <>
            <div className="my-2" />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>To be added.</Tooltip>}>           
            {presetObject.dynamic_option === 'constant' ? (<Accordion.Header>Add income requirements
            <span className="badge-space">
            <Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : <></>}</Badge>
            </span>
            </Accordion.Header>) : presetObject.dynamic_option === 'constantbonus' ? (<Accordion.Header>Add 'fixed' income requirements
            <span className="badge-space">
            <Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : <></>}</Badge>
            </span>
            </Accordion.Header>) : (<Accordion.Header>Add minimum income requirements
            <span className="badge-space">
            <Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : <></>}</Badge>
            </span>
            </Accordion.Header>)}
            </OverlayTrigger>

            <Accordion.Body className="bg-dark text-light">

            <Button className="btn btn-success" onClick={openIncomeModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isIncomeModalOpen} onHide={closeIncomeModal}>
            <Modal.Header closeButton>
            <Modal.Title>Income requirement</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="incomeName" className="small">Description:</Form.Label>
                <Form.Control
                  name="incomeName"
                  id="incomeName"
                  className="form-control border-secondary"
                  type="text"
                  value={incomeName}
                  onChange={(e) => setIncomeName(e.target.value)}
                  placeholder="Name"
                />
            {errors.incomeName && <Alert className="alert alert-danger">{errors.incomeName}</Alert>}
            </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="incomeYear" className="fw-light" className="small">Start age:</Form.Label>
                <Form.Control
                  name="incomeYear"
                  id="incomeYear"
                  className="form-control border-secondary"
                  type="number"
                  value={incomeYear}
                  onChange={(e) => setIncomeYear(e.target.value)}
                  placeholder="AgeStart"
                  min="1"           
                />
              </Form.Group>
              {errors.incomeYear && <Alert className="alert alert-danger">{errors.incomeYear}</Alert>}
              <Form.Group>
                <Form.Label htmlFor="incomeYearEnd" className="fw-light" className="small">End age:</Form.Label>
                <Form.Control
                  name="incomeYearEnd"
                  id="incomeYearEnd"
                  className="form-control border-secondary"
                  type="number"
                  value={incomeYearEnd}
                  onChange={(e) => setIncomeYearEnd(e.target.value)}
                  placeholder="AgeEnd"
                  min="1"           
                />
              </Form.Group>
              {errors.incomeYearEnd && <Alert className="alert alert-danger">{errors.incomeYearEnd}</Alert>}
              <Form.Group>
                <Form.Label htmlFor="incomeAmount" className="fw-light" className="small">Amount (current):</Form.Label>
                <Form.Control
                  name="incomeAmount"
                  id="incomeAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={incomeAmount}
                  onChange={(e) => setIncomeAmount(e.target.value)}
                  placeholder="Amount"
                  min="1"
                />
                {errors.incomeAmount && <Alert className="alert alert-danger">{errors.incomeAmount}</Alert>}
                </Form.Group>

                <Form.Group>
                <Form.Label htmlFor="incomeIndex" className="fw-light" className="small">Index (Y/N):</Form.Label>
                <Form.Select
                  name="incomeIndex"
                  id="incomeIndex"
                  className="form-control border-secondary"
                  value={incomeIndex}
                  onChange={(e) => setIncomeIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="2">Indexed (differential rates)</option>
                  <option value="0">Not Indexed</option>
                  <option value="3">Not Indexed (differential rates)</option>
                </Form.Select>
                </Form.Group>

                {incomeIndex === '0' || incomeIndex === '1' ? 
                <>
                <Form.Group>
                <Form.Label htmlFor="incomeIncrease" className="fw-light" className="small">Inc. over index up to start age (%):</Form.Label>
                <Form.Control
                  name="incomeIncrease"
                  id="incomeIncrease"
                  className="form-control border-secondary"
                  type="number"
                  value={incomeIncrease}
                  onChange={(e) => setIncomeIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                </Form.Group>
                </>
                :
                <>
                <Form.Group>
                <Form.Label htmlFor="incomeIncrease" className="fw-light" className="small">Inc. to index up to income start (%):</Form.Label>
                <Form.Control
                  name="incomeIncrease"
                  id="incomeIncrease"
                  className="form-control border-secondary"
                  type="number"
                  value={incomeIncrease}
                  onChange={(e) => setIncomeIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>                  
                <Form.Group>
                <Form.Label htmlFor="incomeIncrease2" className="fw-light" className="small">Inc. to index from income start (%):</Form.Label>
                <Form.Control
                  name="incomeIncrease2"
                  id="incomeIncrease2"
                  className="form-control border-secondary"
                  type="number"
                  value={incomeIncrease2}
                  onChange={(e) => setIncomeIncrease2(e.target.value)}
                  placeholder="%"
                />
                {errors.incomeIncrease2 && <Alert className="alert alert-danger">{errors.incomeIncrease2}</Alert>}
                </Form.Group>
                </>}
                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleIncomeChange }>
                Enter
                </Button>
                </Modal.Footer>
                </Modal>

            <Row>
              {presetObject.income_requirements.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light small">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Start year (age): {item.year}
                      </Card.Text>     
                      <Card.Text className="tight-spacing">
                        End year (age): {item.yearend}
                      </Card.Text>                                     
                      <Card.Text className="tight-spacing" >
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' || item.index === '2' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. to index up to start (%): {item.increase}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. to index from start (%): {item.increase2}
                      </Card.Text>                      
                      <Button variant="danger" onClick={() => handleIncomeDeleteItem(index)}>
                        Delete
                      </Button>
                      <span> </span>
                       <Button variant="info" onClick={() => handleIncomeModityItem(index)}>
                      Modify
                      </Button>   
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>


            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>            
            </> : <></>}  

            {presetObject.dynamic_option === 'constantflex' || presetObject.dynamic_option === 'constantbonus' ? (<>
              <div className="my-2" />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>To be added.</Tooltip>}>           
            <Accordion.Header>Add 'bonus' income targets
            <span className="badge-space">
            <Badge bg="info">{presetObject.flex_income_requirements.length ? presetObject.flex_income_requirements.length : <></>}</Badge>
            </span>
            </Accordion.Header>
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">

            <Button className="btn btn-success" onClick={openBonusModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isBonusModalOpen} onHide={closeBonusModal}>
            <Modal.Header closeButton>
            <Modal.Title>'Bonus' income requirement</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="flexIncomeName" className="small">Description:</Form.Label>
                <Form.Control
                  name="flexIncomeName"
                  id="flexIncomeName"
                  className="form-control border-secondary"
                  type="text"
                  value={flexIncomeName}
                  onChange={(e) => setFlexIncomeName(e.target.value)}
                  placeholder="Name"     
                />
              {errors.flexIncomeName && <Alert className="alert alert-danger">{errors.flexIncomeName}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="flexIncomeYear" className="fw-light" className="small">Start age:</Form.Label>
                <Form.Control
                  name="flexIncomeYear"
                  id="flexIncomeYear"
                  className="form-control border-secondary"
                  type="number"
                  value={flexIncomeYear}
                  onChange={(e) => setFlexIncomeYear(e.target.value)}
                  placeholder="AgeStart"
                  min="1"           
                />
              {errors.flexIncomeYear && <Alert className="alert alert-danger">{errors.flexIncomeYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="flexIncomeYearEnd" className="fw-light" className="small">End age:</Form.Label>
                <Form.Control
                  name="flexIncomeYearEnd"
                  id="flexIncomeYearEnd"
                  className="form-control border-secondary"
                  type="number"
                  value={flexIncomeYearEnd}
                  onChange={(e) => setFlexIncomeYearEnd(e.target.value)}
                  placeholder="AgeEnd"
                  min="1"           
                />
              {errors.flexIncomeYearEnd && <Alert className="alert alert-danger">{errors.flexIncomeYearEnd}</Alert>}
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="flexIncomeAmount" className="fw-light" className="small">Amount (current):</Form.Label>
                <Form.Control
                  name="flexIncomeAmount"
                  id="flexIncomeAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={flexIncomeAmount}
                  onChange={(e) => setFlexIncomeAmount(e.target.value)}
                  placeholder="Amount"
                  min="1"
                />
                {errors.flexIncomeAmount && <Alert className="alert alert-danger">{errors.flexIncomeAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="flexIncomeIndex" className="fw-light" className="small">Index (Y/N):</Form.Label>
                <Form.Select
                  name="flexIncomeIndex"
                  id="flexIncomeIndex"
                  className="form-control border-secondary"
                  value={flexIncomeIndex}
                  onChange={(e) => setFlexIncomeIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="flexIncomeIncrease" className="fw-light" className="small">Inc. over index (%):</Form.Label>
                <Form.Control
                  name="flexIncomeIncrease"
                  id="flexIncomeIncrease"
                  className="form-control border-secondary"
                  type="number"
                  value={flexIncomeIncrease}
                  onChange={(e) => setFlexIncomeIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.flexIncomeIncrease && <Alert className="alert alert-danger">{errors.flexIncomeIncrease}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleFlexIncomeChange }>
                Enter
                </Button>
                </Modal.Footer>
                </Modal>

            <Row>
              {presetObject.flex_income_requirements.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Start year (age): {item.year}
                      </Card.Text>     
                      <Card.Text className="tight-spacing">
                        End year (age): {item.yearend}
                      </Card.Text>                                     
                      <Card.Text className="tight-spacing">
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. over index (%): {item.increase}
                      </Card.Text>
                      <Button variant="danger" onClick={() => handleFlexIncomeDeleteItem(index)}>
                        Delete
                      </Button>
                      <span> </span>
                       <Button variant="info" onClick={() => handleFlexIncomeModityItem(index)}>
                      Modify
                      </Button>   
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>
            </>) : (<></>)}    

            <hr className="my-2" />           
            <h6>Portfolio asset mix:</h6>
            <div className="my-2" />

            <Form.Group as={Col}>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter asset mix source.</Tooltip>}>           
            <Form.Label htmlFor="asset_mix_source" className="fw-light">Asset mix source:</Form.Label>
            </OverlayTrigger>
            <Form.Select 
              name="asset_mix_source"
              id="asset_mix_source"
              className="form-control bg-secondary border-secondary text-light"
              onChange={(e) => handleSelectChange('asset_mix_source', e.target.value)}
              value={presetObject.asset_mix_source}>
              <option value="0" >Use asset mix in current portfolio</option>
              <option value="1" >Enter asset mix for simulation</option>
            </Form.Select>
            </Form.Group>

            {presetObject.asset_mix_source === "0" ? <></> : 
            <>
            <div className="my-2" />
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter portfolio asset mix at beginning of simulation (simulation rebalances asset mix each year)</Tooltip>}>           
            <span></span>
            </OverlayTrigger>
            {(presetObject.data_option === 'forward' || presetObject.data_option === 'forwardUSD' || presetObject.data_option === 'forwardUSDglobal') ? (
            <div>
            <Row>
            <Form.Group as={Col}>
            <Form.Label htmlFor="asset_mix_equity" className="fw-light">Equity (of 100%):</Form.Label>
            <Form.Control
              type="number"
              name="asset_mix_equity"
              id="asset_mix_equity"
              value={presetObject.asset_mix_equity}
              onChange={(e) => handleObjectChange('asset_mix_equity', e.target.value)}
              className="form-control bg-secondary border-secondary text-light">
            </Form.Control>
            {errors.asset_mix_equity && <Alert className="alert alert-danger">{errors.asset_mix_equity}</Alert>}
            </Form.Group>
            <Form.Group as={Col}>
            <Form.Label htmlFor="asset_mix_bond" className="fw-light">Bond (conventional) (of 100%):</Form.Label>
            <Form.Control
              type="number"
              name="asset_mix_bond"
              id="asset_mix_bond"
              value={presetObject.asset_mix_bond}
              onChange={(e) => handleObjectChange('asset_mix_bond', e.target.value)}
              className="form-control bg-secondary border-secondary text-light">
            </Form.Control>
            {errors.asset_mix_bond && <Alert className="alert alert-danger">{errors.asset_mix_bond}</Alert>}
            </Form.Group>

            <Form.Group as={Col}>
            <Form.Label htmlFor="asset_mix_index_bond" className="fw-light">Bond (index) (of 100%):</Form.Label>
            <Form.Control
              type="number"
              name="asset_mix_index_bond"
              id="asset_mix_index_bond"
              value={presetObject.asset_mix_index_bond}
              onChange={(e) => handleObjectChange('asset_mix_index_bond', e.target.value)}
              className="form-control bg-secondary border-secondary text-light">
            </Form.Control>
            {errors.asset_mix_index_bond && <Alert className="alert alert-danger">{errors.asset_mix_index_bond}</Alert>} 
            </Form.Group>
            </Row> 
            </div>
            ) : (
              <div>
              <Row>
              <Form.Group as={Col}>
              <Form.Label htmlFor="asset_mix_equity" className="fw-light">Equity:</Form.Label>
              <Form.Control
                type="number"
                name="asset_mix_equity"
                id="asset_mix_equity"
                value={presetObject.asset_mix_equity}
                onChange={(e) => handleObjectChange('asset_mix_equity', e.target.value)}
                className="form-control bg-secondary border-secondary text-light">
              </Form.Control>
              {errors.asset_mix_equity && <Alert className="alert alert-danger">{errors.asset_mix_equity}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
              <Form.Label htmlFor="asset_mix_bond" className="fw-light">Bond (conventional):</Form.Label>
              <Form.Control
                type="number"
                name="asset_mix_bond"
                id="asset_mix_bond"
                value={presetObject.asset_mix_bond}
                onChange={(e) => handleObjectChange('asset_mix_bond', e.target.value)}
                className="form-control bg-secondary border-secondary text-light">
              </Form.Control>
              {errors.asset_mix_bond && <Alert className="alert alert-danger">{errors.asset_mix_bond}</Alert>}
              </Form.Group>
              </Row>
              </div>
            )
            }
            </>}

            <hr className="my-2" />           
            <h6>Optional annuitisation:</h6>
            <div className="my-2" />

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Purchase annuity into portfolio that will provide a lifetime annual income.</Tooltip>}>           
            <Accordion.Header>(Optional) purchase annuity into portfolio
            <span className="badge-space">
            <Badge bg="info">{presetObject.annuity_pension.length ? presetObject.annuity_pension.length : <></>}</Badge>
            </span>
            </Accordion.Header>
            </OverlayTrigger>
            <Accordion.Body className="bg-dark text-light">

            <Button className="btn btn-success" onClick={openAnnuityModal}>
            Add
            </Button>
            <div className="my-2" />

            <Modal show={isAnnuityModalOpen} onHide={closeAnnuityModal}>
            <Modal.Header closeButton>
            <Modal.Title>Annuity</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
                <Form.Label htmlFor="annuityName" className="small">Description:</Form.Label>
                <Form.Control
                  name="annuityName"
                  id="annuityName"
                  className="form-control border-secondary"
                  type="text"
                  value={annuityName}
                  onChange={(e) => setAnnuityName(e.target.value)}
                  placeholder="Name"         
                />
              {errors.annuityName && <Alert className="alert alert-danger">{errors.annuityName}</Alert>}
              </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="annuityYear" className="small">Start year (age):</Form.Label>
                <Form.Control
                  name="annuityYear"
                  id="annuityYear"
                  className="form-control border-secondary"
                  type="number"
                  value={annuityYear}
                  onChange={(e) => setAnnuityYear(e.target.value)}
                  placeholder="Year"
                  min="1"           
                />
              {errors.annuityYear && <Alert className="alert alert-danger">{errors.annuityYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annuity annual income starting in year annuity is purchased.</Tooltip>}>           
                <Form.Label htmlFor="annuityAmount" className="small">Annual income (purchase year):</Form.Label>
                </OverlayTrigger>

                <Form.Control
                  name="annuityAmount"
                  id="annuityAmount"
                  className="form-control border-secondary"
                  type="number"
                  value={annuityAmount}
                  onChange={(e) => setAnnuityAmount(e.target.value)}
                  placeholder="Income"
                  min="1"
                />
                {errors.annuityAmount && <Alert className="alert alert-danger">{errors.annuityAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter (year 1) annuity income as percentage notional purchase price.</Tooltip>}>           
                <Form.Label htmlFor="annuityPrice" className="small">Price (as % notional):</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="annuityPrice"
                  id="annuityPrice"
                  className="form-control border-secondary"
                  type="number"
                  value={annuityPrice}
                  onChange={(e) => setAnnuityPrice(e.target.value)}
                  placeholder="Price"
                />
                {errors.annuityPrice && <Alert className="alert alert-danger">{errors.annuityPrice}</Alert>}
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="annuityIndex" className="small">Annual inc. (select):</Form.Label>
                <Form.Select
                  name="annuityIndex"
                  id="annuityIndex"
                  className="form-control border-secondary"
                  value={annuityIndex}
                  onChange={(e) => setAnnuityIndex(e.target.value)}
                  defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label htmlFor="annuityIncrease" className="small">Inc. over index (%):</Form.Label>
                <Form.Control
                  name="annuityIndex"
                  id="annuityIndex"
                  className="form-control border-secondary"
                  type="number"
                  value={annuityIncrease}
                  onChange={(e) => setAnnuityIncrease(e.target.value)}
                  placeholder="%"
                />
                {errors.annuityIncrease && <Alert className="alert alert-danger">{errors.annuityIncrease}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleAnnuityPensionChange }>
                Enter
                </Button>
                </Modal.Footer>
                </Modal>

          <Row>
              {presetObject.annuity_pension.map((item, index) => (
                <Col key={index} md={4} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        Start year (age): {item.year}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Annual amount ($,£): {item.amount}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Price (% notional): {item.price}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Indexed: {item.index === '1' ? 'Yes' : 'No'}
                      </Card.Text>
                      <Card.Text className="tight-spacing">
                        Inc. over index (%): {item.increase}
                      </Card.Text>
                      <Button variant="danger" onClick={() => handleAnnuityPensionDeleteItem(index)}>
                        {/* <i className="bi bi-trash"></i> */}
                        Delete
                      </Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handleAnnuityPensionModityItem(index)}>
                        Modify
                      </Button>                      
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>


            <hr className="my-2" />           
            <h6>Taxes, fees and data-set choice:</h6>
            <div className="my-2" />
            {/* <p>{presetObject.data_option}</p> */}
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select data-set that will be used for backtesting. Historic data sets use all historic data. Current market yield data sets use current market bond yields combined with historic equity and CPI data for scenario testing.</Tooltip>}>           
            <Form.Label htmlFor="data_option" className="fw-light">Select backtesting data:</Form.Label>
            </OverlayTrigger>
            
            <Form.Select
              name="data_option"
              id="data_option"
              className="form-control bg-secondary border-secondary text-light"
              onChange={(e) => handleDataCalibrationChange('data_option', e.target.value)}     
              value={presetObject.data_option}>
              <option value="backUSDshiller" >USD historic data for all assets (US Equities, end 1870-2023)</option>
              <option value="backUSDshort" >USD historic data for all asset (US Equities, Trinity study end 1925-1995)</option>
              <option value="backUSDglobal" >USD historic data for all assets (Global Equities, end 1899-2023)</option>   
              <option value="forwardUSD">USD current market yields for bonds, historic data equities (US Equities, end 1870-2023)</option>
              <option value="forwardUSDglobal">USD current market yields for bonds, historic data for equities (Global Equities, end 1899-2023)</option>
              <option disabled>----------</option>
              <option value="back" >GBP historic data for all assets (Global Equities, end 1899-2023)</option>
              <option value="forward">GBP current market yields for bonds, historic data for equities (Global Equities, end 1899-2023)</option>
		        </Form.Select>

            <div className="mt-2"></div>
            <Accordion defaultActiveKey="0">
			      <Accordion.Item eventKey="1">
				    <Accordion.Header>(Optional) further settings (taxes, fees, etc)</Accordion.Header>
				    <Accordion.Body className="bg-dark text-light">            
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Reduces total returns on equity portion of general acccount portfion of portfolio in simulation to cover dividend and capital gains taxes. </Tooltip>}>           
            <Form.Label htmlFor="equity_tax" className="fw-light">Tax on general account equity returns (0-100%):</Form.Label>
            </OverlayTrigger>
            <Form.Control type="number" name="equity_tax" id="equity_tax" value={presetObject.equity_tax} onChange={(e) => handleObjectChange('equity_tax', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.equity_tax && <span className="alert alert-danger">{errors.equity_tax}</span>}
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Reduces total returns on bond portion of general acccount portfion of portfolio in simulation to cover dividend and capital gains taxes. </Tooltip>}>           
            <Form.Label htmlFor="bond_tax" className="fw-light">Tax on general account bond returns (0-100%):</Form.Label>
            </OverlayTrigger> 
            <Form.Control type="number" name="bond_tax" id="bond_tax" value={presetObject.bond_tax} onChange={(e) => handleObjectChange('bond_tax', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.bond_tax && <span className="alert alert-danger">{errors.bond_tax}</span>}
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select to apply bond tax to inflation adjustment component of return of inflation linked bonds</Tooltip>}>           
            <Form.Label htmlFor="apply_tax_to_inflation" className="fw-light">Inflation linked bond tax treatment:</Form.Label>
            </OverlayTrigger>
            <Form.Select
              name="apply_tax_to_inflation"
              id="apply_tax_to_inflation"
              className="form-control bg-secondary border-secondary text-light"
              onChange={(e) => handleObjectChange('apply_tax_to_inflation', e.target.value)}             
              // defaultValue="1">
              value={presetObject.apply_tax_to_inflation}>
              <option value="0" >No tax applied to inflation adjustment</option>                
              <option value="1" >Tax applied to inflation adjustment</option>
		        </Form.Select>
            {errors.apply_tax_to_inflation && <span className="alert alert-danger">{errors.apply_tax_to_inflation}</span>}

            
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter income tax rate that will be charged on withdrawals from tax deferred portion of portfolio. Gross deduction from portfolio in simulation is scaled up to cover these taxes so that net income equals income requirement.</Tooltip>}>           
            <Form.Label htmlFor="draw_tax" className="fw-light">Income tax on tax deferred withdrawals (0-100%):</Form.Label>
            </OverlayTrigger>


            <Form.Control type="number" name="draw_tax" id="draw_tax" value={presetObject.draw_tax} onChange={(e) => handleObjectChange('draw_tax', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.draw_tax && <span className="alert alert-danger">{errors.draw_tax}</span>}
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter income tax rate that will be charged on annuity income for the interest rate return component of that income. Simulation also captures the income tax that will be charged on the non-interest rate return component of annuity income in proportion to the amount of the portfolio that is in tax deferred funds. Annuity purchase cost in simulation is scaled up to cover these taxes. </Tooltip>}>
            <Form.Label htmlFor="annuity_tax_rate" className="fw-light">Income tax on annuity interest rate return component (0-100%):</Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="annuity_tax_rate"
              id="annuity_tax_rate"
              value={presetObject.annuity_tax_rate}
              onChange={(e) => handleObjectChange('annuity_tax_rate', e.target.value)}
              className="form-control bg-secondary border-secondary text-light">
            </Form.Control>
            {errors.annuity_tax_rate && <Alert className="alert alert-danger">{errors.annuity_tax_rate}</Alert>}
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter income tax rate that will be deducted on state pension income. </Tooltip>}>
            <Form.Label htmlFor="annuity_tax_rate2" className="fw-light">Income on state pension income (0-100%):</Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="annuity_tax_rate2"
              id="annuity_tax_rate2"
              value={presetObject.annuity_tax_rate2}
              onChange={(e) => handleObjectChange('annuity_tax_rate2', e.target.value)}
              className="form-control bg-secondary border-secondary text-light">
            </Form.Control>
            {errors.annuity_tax_rate2 && <Alert className="alert alert-danger">{errors.annuity_tax_rate2}</Alert>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter income tax rate that will be deducted on occupational pension income. </Tooltip>}>
            <Form.Label htmlFor="annuity_tax_rate3" className="fw-light">Income on occupational pension income (0-100%):</Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="annuity_tax_rate3"
              id="annuity_tax_rate3"
              value={presetObject.annuity_tax_rate3}
              onChange={(e) => handleObjectChange('annuity_tax_rate3', e.target.value)}
              className="form-control bg-secondary border-secondary text-light">
            </Form.Control>
            {errors.annuity_tax_rate3 && <Alert className="alert alert-danger">{errors.annuity_tax_rate3}</Alert>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Deducts annual charge from portfolio value in simulation - fees reduce returns</Tooltip>}>           
            <Form.Label htmlFor="fees" className="fw-light">Average portfolio fees (basis points):</Form.Label>
            </OverlayTrigger>
            <Form.Control type="number" name="fees" id="fees" value={presetObject.fees} onChange={(e) => handleObjectChange('fees', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.fees && <span className="alert alert-danger">{errors.fees}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add net credit spread return to forward looking conventional bond yield (basis points) to simulate holding credit/corporate bonds rather than government bonds</Tooltip>}>           
            <Form.Label htmlFor="spread" className="fw-light">Add net credit spread return to forward looking conventional bond yield (basis points):</Form.Label>
            </OverlayTrigger>
            <Form.Control type="number" name="spread" id="spread" value={presetObject.spread} onChange={(e) => handleObjectChange('spread', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.spread && <span className="alert alert-danger">{errors.spread}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add alpha spread return to equity returns</Tooltip>}>           
            <Form.Label htmlFor="equity_alpha" className="fw-light">Add alpha spread return to all equity returns (basis points):</Form.Label>
            </OverlayTrigger>
            <Form.Control type="number" name="equity_alpha" id="equity_alpha" value={presetObject.equity_alpha} onChange={(e) => handleObjectChange('equity_alpha', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.equity_alpha && <span className="alert alert-danger">{errors.equity_alpha}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Add alpha spread return to bond returns</Tooltip>}>           
            <Form.Label htmlFor="fixed_income_alpha" className="fw-light">Add alpha spread return to all bond returns (basis points):</Form.Label>
            </OverlayTrigger>
            <Form.Control type="number" name="fixed_income_alpha" id="fixed_income_alpha" value={presetObject.fixed_income_alpha} onChange={(e) => handleObjectChange('fixed_income_alpha', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
            {errors.fixed_income_alpha && <span className="alert alert-danger">{errors.fixed_income_alpha}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Linear simulation uses back-testing cycles from the first year of the historic data set up until the last completable cycle in the data set (e.g. if dataset is from 1900-2023 and the simulation is 30 years, the model will use 94 backtesting cycles with the first from 1900-1930 and the last from 1993-2023). Circular simulation starts a back-testing cycle in every year of the historic data set - if there are insufficent years to finish the cycle, it uses again the years at the beginning of the data set (e.g. if dataset is from 1900-2023 and the simulation is 30 years, the model will use 124 backtesting cycles with the first from 1900-1930, the 95th using 1994-2023 plus 1900 (to make 30 years) and the last from using 2023 plus 1900-1929 (again, to make 30 years)). </Tooltip>}>           
            <Form.Label htmlFor="circular_simulation" className="fw-light">Simulation methodology:</Form.Label>
            </OverlayTrigger>
            <Form.Select
              name="circular_simulation"
              id="circular_simulation"
              className="form-control bg-secondary border-secondary text-light"
              onChange={(e) => handleObjectChange('circular_simulation', e.target.value)}             
              // defaultValue="1">
              value={presetObject.circular_simulation}>
              <option value="1" >Circular exploratory simulation</option>
              <option value="0" >Linear exploratory simulation</option>
		        </Form.Select>
            {errors.circular_simulation && <span className="alert alert-danger">{errors.circular_simulation}</span>}
            
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>First year (end of) of historic dataset for backtesting </Tooltip>}>           
            <Form.Label htmlFor="data_start_year" className="fw-light">First year (end of) of historic dataset for backtesting:</Form.Label>
            </OverlayTrigger>
            <Form.Select
              type="number" 
              name="data_start_year" 
              id="data_start_year" 
              value={presetObject.data_start_year} 
              onChange={(e) => handleObjectChange('data_start_year', e.target.value)} 
              className="form-control bg-secondary border-secondary text-light" 
              >
              {/* {yearList.map(number => (<option key={index} value={number}>{number}</option>))} */}
              {yearList.map((number, index) => (<option key={index} value={number}>{number}</option>))}
            </Form.Select>
            {errors.data_start_year && <span className="alert alert-danger">{errors.data_start_year}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Last year (end of) of historic dataset for backtesting </Tooltip>}>           
            <Form.Label htmlFor="data_end_year" className="fw-light">Last year (end of) of historic dataset for backtesting:</Form.Label>
            </OverlayTrigger>
            <Form.Select
              type="number" 
              name="data_end_year" 
              id="data_end_year" 
              value={presetObject.data_end_year} 
              onChange={(e) => handleObjectChange('data_end_year', e.target.value)} 
              className="form-control bg-secondary border-secondary text-light" 
              >
              {/* {yearList.map(number => (<option value={number}>{number}</option>))} */}
              {yearList.map((number, index) => (<option key={index} value={number}>{number}</option>))}
            </Form.Select>
            {errors.data_end_year && <span className="alert alert-danger">{errors.data_end_year}</span>}

            </Accordion.Body>
			      </Accordion.Item>
            </Accordion>
            <hr className="my-2" />

            <br></br>

            {loading2 ? (<ButtonExample data={buttonlabel}/>) : (<button className="btn btn-success" onClick={runMixMaxOptimisationAndReCalc }>Auto optimise asset mix (max return)</button>)}
            <span> </span>
            {isSmallScreen ? <div className="mt-2"></div> : ''}
            {loading3 ? (<ButtonExample data={buttonlabel}/>) : (<button className="btn btn-success" onClick={runMixMinOptimisationAndReCalc }>Auto optimise asset mix (min risk)</button>)}
            <div className="mt-2"></div>
            {loading4 ? (<ButtonExample data={buttonlabel}/>) : (<button className="btn btn-success" onClick={handleSubmit }>Run simulation</button>)}
            <br></br>
            {/* <div className="my-2"></div> */}
            {/* <button className="btn btn-danger" onClick={openResetModal }>Reset all fields</button> */}
            {/* <br></br> */}
            
            <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {modalmessage}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={closeModal}>Continue</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isResetModalOpen} onHide={closeResetModal }>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {modalResetMessage}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeResetModal }>
                Cancel
              </Button>
              <Button variant="primary" onClick={resetPlan }>
                Confirm
              </Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isRetrieveModalOpen} onHide={closeRetrieveModal} centered>
            <Modal.Header closeButton>
            <Modal.Title>User Plans</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* {error && <p className="text-danger">{error}</p>} */}
            {plans.length > 0 ? (
            <Table hover className="horizontal-border-only">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>#</th>
                  <th style={{ width: '55%' }}>Plan Title</th>
                  <th style={{ width: '40%' }}>Actions</th>     
                </tr>
              </thead>
              <tbody>
                {plans.map((plan, index) => (
                  <tr key={plan.id}>
                    <td>{index + 1}</td>
                    <td>{plan.plan_title}</td>
                    <td>
                      <Button 
                        variant="info" 
                        onClick={() => retrievePlan(plan.id)} 
                        className="me-1"
                      >Retrieve</Button>
                      <Button 
                        variant="danger" 
                        onClick={() => handleOpenDeleteModal(plan.id)}
                        className="me-1" 
                      >Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            ) : (
            <p>No plans found.</p>
            )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeRetrieveModal}>
            Close
            </Button>
            </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Are you sure you want to delete this plan? This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
            </Button>
            <Button 
            variant="danger" 
            onClick={() => deletePlan(selectedPlanId)} // Call deletePlan only after confirmation
            >
            Confirm
            </Button>
            </Modal.Footer>
            </Modal>

            {/* Login Modal */}
            <Modal show={isReLoginModalOpen} onHide={closeReLoginModal}>
              <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Error connecting to server. Action could not be completed. Please try re-logging in and re-trying action.</p>
                <ReLoginForm onClose={closeReLoginModal} />
                {/* <p>Test</p> */}
              </Modal.Body>
            </Modal>

          </div>
          <div className="col-sm-2"></div>
          </div>

          <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
          <p className="lead">Simulation results:</p>

            {/* <p className={isSmallScreen ? 'small' : ''}>Portfolio starting size ($,£): {portfolioTotal.toLocaleString()}</p> */}
            {portfolioTotal === null || portfolioTotal === undefined ? <p className={isSmallScreen ? 'small' : ''}>Portfolio starting size ($,£): {portfolioTotal}</p> : <p className={isSmallScreen ? 'small' : ''}>Portfolio starting size ($,£): {portfolioTotal.toLocaleString()}</p>}
            {presetObject.data_direction === 'forward' ? <p className={isSmallScreen ? 'small' : ''}>Asset mix: equity = {presetObject.asset_mix_equity.toFixed(1)}%, bonds (conventional) = {presetObject.asset_mix_bond.toFixed(1)}%, bonds (index) = {presetObject.asset_mix_index_bond.toFixed(1)}%</p> : <p className={isSmallScreen ? 'small' : ''}>Asset mix: equity = {presetObject.asset_mix_equity.toFixed(1)}%, bonds (conventional) = {presetObject.asset_mix_bond.toFixed(1)}%</p> }
            

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Backtest failure rate gives the percentage of scenarios run in which the portfolio value fell to zero before the end of the simulation i.e. the strategy failed</Tooltip>}>           
            <p className={isSmallScreen ? 'small' : ''}>Backtest simulation cycle failure rate: {result.toFixed(1)}%</p>
            </OverlayTrigger>
            {switchvalue === 4 ? (
            <div>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Average annual income (current value) over all simulation cycles</Tooltip>}>           
            <p className={isSmallScreen ? 'small' : ''}>Average income value ($,£ net of tax): {averagedraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
            </OverlayTrigger>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Average annual income (current value) over all simulation cycles with each withdrawal multiplied by percentage probability of person living to the age at time received in simulation (data: US life expectancy, average male/female).</Tooltip>}>           
            <p className={isSmallScreen ? 'small' : ''}>Average mortality adjusted income value ($,£ net of tax): {averageadjusteddraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
            </OverlayTrigger>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Average discounted sum of annual income (current value) over simulation cycle with each withdrawal multiplied by percentage probability of person living to the age at time received in simulation (data: US life expectancy, average male/female). Discount adjustment uses current market real premium / discount (after inflation) taken from government inflation linked bond curve).</Tooltip>}>           
            <p className={isSmallScreen ? 'small' : ''}>Average discounted sum of mortality adjusted income values ($,£ net of tax): {sumdrawadjustedavg.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
            </OverlayTrigger>     
            </div>       
            ): (<></>)}

            
            {switchvalue === 1 ? (switchvalue4 === 1 ? (<DistChart data={allresult} data2={presetObject.years} data3={presetObject.data_direction} data4={presetObject.asset_mix_equity} data5={yearsForChart} data6={'Development of portfolio value through each back-test simulation cycle ($,£ current value)'}/>) : switchvalue4 === 2 ? (<DistValueFanChart data={portfoliovaluepercentiles} data2={presetObject.years} data3={presetObject.data_direction} data4={presetObject.asset_mix_equity} data5={yearsForChart}/>) : switchvalue4 === 3 ? (<DistChart data={allresultGA} data2={presetObject.years} data3={presetObject.data_direction} data4={presetObject.asset_mix_equity} data5={yearsForChart} data6={'Development of GA portfolio value through each back-test simulation cycle ($,£ current value)'}/>) : switchvalue4 === 4 ? (<DistChart data={allresultTE} data2={presetObject.years} data3={presetObject.data_direction} data4={presetObject.asset_mix_equity} data5={yearsForChart} data6={'Development of TE portfolio value through each back-test simulation cycle ($,£ current value)'}/>) : <DistChart data={allresultTD} data2={presetObject.years} data3={presetObject.data_direction} data4={presetObject.asset_mix_equity} data5={yearsForChart} data6={'Development of TD portfolio value through each back-test simulation cycle ($,£ current value)'}/>) 
            : switchvalue === 2 ? (<MaxMinPlotChart data={decresult}/>) 
            : switchvalue === 3 ? (switchvalue3 === 1 ? (<BestSWRChart data={bestswrs} data2={resultyears}/>) : (switchvalue3 === 2 ? (<SafeFundingChart data={safefundinglevel} data2={presetObject.years} data3={presetObject.years_contributions+presetObject.years_between} data5={yearsForChartShort}/>) : switchvalue3 === 3 ? (<SafeFundingLevelChart data={safefunding} data2={presetObject.years} data3={presetObject.years_contributions+presetObject.years_between} data5={yearsForChartShort}/>) : <TradeOffAnalysis data1={presetObject} data2={setErrors} data3={setIsModalOpen} data4={setModalmessage}/> ))
            : switchvalue === 4 ? (switchvalue2 === 1 ? (<DrawDistChart data={alldrawstreams} data2={presetObject.years} data3={presetObject.annuity_percent_withdrawal} data5={yearsForChartShort}/>) : (switchvalue2 === 2 ? (<DrawFanChart data={incomepercentiles} data2={presetObject.years} data3={presetObject.annuity_percent_withdrawal} data5={yearsForChartShort}/>) : (switchvalue2 === 3 ? (<DrawHistChart data={drawhistdata} data3={presetObject.annuity_percent_withdrawal}/>) : (<DistTypeChart data={drawstreamsbytype} data2={presetObject.years} data3={presetObject.annuity_percent_withdrawal} data5={yearsForChartShort}/>))))
            : (<></>)}
            
            <br></br>

            {switchvalue === 4 ? (
            <ToggleButtonGroup type="radio" name="options2" value={switchvalue2} onChange={handleSwitchChange2}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b1" value={1} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            Income / withdrawal by year (all results)
            </ToggleButton>    
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b2" value={2} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            Income / withdrawal by year (by percentile)
            </ToggleButton>    
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b3" value={3} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            Distribution of income / withdrawal
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b4" value={4} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            Income by type by year
            </ToggleButton>
            </ToggleButtonGroup>
            ) : switchvalue === 3 ? (
            <ToggleButtonGroup type="radio" name="options3" value={switchvalue3} onChange={handleSwitchChange3}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c1" value={1} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
              Safe initial funding by back-test cycle
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c3" value={3} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
              Safe funding level through  years
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c2" value={2} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
              Dynamic SWR through years
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c4" value={4} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
              Safe funding level by asset mix
            </ToggleButton>            
            </ToggleButtonGroup>
            ) : switchvalue === 1 ? (
            <ToggleButtonGroup type="radio" name="options4" value={switchvalue4} onChange={handleSwitchChange4}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d1" value={1} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            All results
            </ToggleButton>           
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d2" value={2} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            Results by percentile
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d3" value={3} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            All results (general account funds)
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d4" value={4} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            All results (tax exempt funds)
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d5" value={5} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
            All results (tax deferred funds)
            </ToggleButton>            
            </ToggleButtonGroup>
            ) : (<></>)}
          
            <div className="mt-2"></div>

            <ToggleButtonGroup type="radio" name="options" value={switchvalue} onChange={handleSwitchChange}>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a1" value={1} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
                Portfolio value by year
              </ToggleButton>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a2" value={2} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
                Distribution of final portfolio value
              </ToggleButton>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a4" value={4} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
                Income / withdrawal analysis
              </ToggleButton>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a3" value={3} variant="secondary" size="sm" style={{ flex: 1, maxWidth: '100px' }}>
                Safe funding analysis
              </ToggleButton>
            </ToggleButtonGroup>
            
          </div>
          <div className="col-sm-2"></div>
          </div>
 
      </Container>
    )
};

export default CalcDash1

