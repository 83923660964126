
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { register } from '../utils/auth';
import { useAuthStore } from '../store/auth';
import useLogin from '../utils/login';

const RegisterForm = ({ onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessageUserName, setErrorMessageUserName] = useState(''); 
    const [errorMessagePassword, setErrorMessagePassword] = useState(''); 
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
    // this is temp to control access
    const permittedUsers = ['toby.pittaway@gmail.com', 'test1@test1.com', 'test2@test2.com', 'test3@test3.com'];

    const { login } = useLogin();

    useEffect(() => {
        if (isLoggedIn()) {
            onClose(); // Close the modal if already logged in
        }
    }, [isLoggedIn, onClose]);

    const resetForm = () => {
        setUsername('');
        setPassword('');
        setPassword2('');
        setErrorMessageUserName(''); // Reset the error message
        setErrorMessagePassword(''); // Reset the error message
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        resetForm(); // Clear all error messages before proceeding

        if (permittedUsers.includes(username)) {

        const response = await register(username, password, password2);
    
        // Check if an error object is returned and if it contains the expected properties (e.g. is not null or undefined which would cause an error when the code comes to error.username)
        if (response?.error) {
            const { error } = response;
    
        if (error.username) {
            setErrorMessageUserName(error.username);
        } else if (error.password) {
            setErrorMessagePassword(error.password);
        }
        } else {
            // auto login...
            await login(username, password);
            resetForm();
            onClose(); // Close the modal on successful registration
        }
        } else {setErrorMessageUserName('Contact administrator for access.')};
    };


    return (
        <section>
            <Form onSubmit={handleRegister}>
                <Form.Group>
                    <Form.Label htmlFor="username" className="small">Username:</Form.Label>
                    <Form.Control 
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="password" className="small">Password:</Form.Label>
                    <Form.Control
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}                
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="confirmPassword" className="small">Confirm Password:</Form.Label>
                    <Form.Control
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}                
                    />
                </Form.Group>
                {errorMessageUserName && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        {errorMessageUserName}
                    </div>
                )}
                {errorMessagePassword && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        {errorMessagePassword}
                    </div>
                )}
                <br />
                <Button variant="success" type="submit">
                    Register
                </Button>
            </Form>
        </section>
    );
};

export default RegisterForm;
