import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import '../styles/mystyles.css'

function DistChart(props) {

  // function isMobileDevice() {
  //   return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  // };

  // function setAspectRatio() {
  //   var aspectRatio = isMobileDevice() ? false : true; // Adjust the values as needed text
  //   return aspectRatio;
  // };

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * 0.3; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  // const labels = ['0', ...Array.from({ length: props.data2 }, (_, index) => `${index + 1}`)];

  const labels = props.data5

  const datasets = props.data.map((dataArray, datasetIndex) => ({
    label: `Scenario ${datasetIndex + 1}`,
    data: dataArray,
    borderColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.2)`,
    backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.2)`,
  }));

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
    legend: {
      display: false,
      },
      title: {
        display: true,
        color: 'white',
        font: {
          size: 14, // Set the font size for the title
        },
        // text: 'Development of portfolio value through each back-test simulation cycle ($,£ current value)',
        text: props.data6,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: 'Age',
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: labels,
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        beginAtZero: true,
        ticks: {
          color: 'white',
          beginAtZero: true,
          callback: function(value, index, ticks) {
            if (value >= 10000000 || value <= -10000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + 'm'} 
            else if (value >= 1000000 || value <= -1000000) {return '' + (Math.round(value / 100000) * 100000).toString().slice(0, -6) + '.' + (Math.round(value / 10000) * 10000).toString().slice(1, -5) + 'm'}
            else if (value >= 1000 || value <= -1000) {return '' + (Math.round(value / 1000) * 1000).toString().slice(0, -3) + 'k'}
            else {return '' + value.toString()};
          },},
      },
    },
  };

  return (
    <div>
      {isSmallScreen ? (
        <div style={{ width: '100%', height: '300px' }}>
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <div style={{ width: '100%', height: `${chartHeight}px` }}>
          <Line data={chartData} options={options} />
        </div>
      )}
      {props.data3 === 'forward' && props.data4 < 100 ? <br></br> : null}
      {props.data3 === 'forward' && props.data4 < 100 ? <p className="small">Notice: Bonds in portfolio are treated as being cash-flow matched against withdrawals. Possible value movements in bonds before maturity are not included in the simulation.</p> : null}
      {props.data3 === 'forward' && props.data4 < 100 && props.data2 > 40 ? <p className="small">The simulation models bond yields over 30 years by assuming forward rates beyond 30 years equal the current 30 year rate. Suitable bonds to cash-flow match withdrawals in over 30-40 years time may not be available and the re-investment risk in rolling over shorter dated bonds is not included in the simulation.</p> : null}
    </div>
  );
};

export default DistChart;


