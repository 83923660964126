import { useAuthStore } from '../store/auth';
import axios from './axios';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useSharedData } from '../components/setdata.js';
import { setAuthUser, setAuthUserToStore, getRefreshToken, isAccessTokenExpired } from '../utils/auth'


function useSetUser() {
    const { setCSRFToken, setAccessToken, setRefreshToken } = useAuthStore();
    const accessToken = useAuthStore((state) => state.getAccessToken());

    const setUser = async () => {
        // ON PAGE LOAD
        // const accessToken = useAuthStore((state) => state.getAccessToken());
        // const accessToken = Cookies.get('access_token');
        // const refreshToken = Cookies.get('refresh_token');
        if (!accessToken) {
        // if (!accessToken || !refreshToken) {
            return;
        }
        if (isAccessTokenExpired(accessToken)) {
            // const response = await getRefreshToken(refreshToken);
            const response = await getRefreshToken();
            // setAuthUser(response.access, response.refresh);
            setAuthUserToStore(response.access);
        } else {
            // setAuthUser(accessToken, refreshToken);
            setAuthUserToStore(accessToken);
        }
    };

    return { setUser };
};

export default useSetUser;