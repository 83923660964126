import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
// import { logout } from '../../utils/auth';
import useLogout from '../../utils/logout';
import Modal from 'react-bootstrap/Modal';
import LoginForm from '../../views/login';
import ResetForm from '../../views/reset';
import RegisterForm from '../../views/register';
// import { useSharedData } from "../../components/setdata.js"

const Navigbar = () => {
  const logInState = useAuthStore().isLoggedIn();
  
  // State for Logout Modal
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState('');

  // State for Login Modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const { logout } = useLogout();

  // Logout Handler (THIS REPLACES THE COMPONENT LOGOUT IN THE VIEWS FOLDER - IT MAY BE NECESSARY TO ADD NAVIGATE LINK TO THIS TO TAKE USER AWAY FROM PRIVATE PAGES IF USER IS ON THOSE PAGES)
  const handleLogout = () => {
    logout();
    setLogoutMessage('You have been logged out');
    setShowLogoutModal(true);
  };

  // Open Handlers
  const handleShowLogin = () => setShowLoginModal(true);
  const handleShowRegister = () => setShowRegisterModal(true);
  const handleShowReset = () => setShowResetModal(true);

  // Close Handlers
  const handleCloseLogout = () => setShowLogoutModal(false);
  const handleCloseLogin = () => setShowLoginModal(false);
  const handleCloseRegister = () => setShowRegisterModal(false);
  const handleCloseReset = () => setShowResetModal(false);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/calc">Simulation Calculator</Nav.Link>
              <NavDropdown title="Learning" id="basic-nav-dropdown" menuVariant="dark">
                <NavDropdown.Item as={Link} to="/">Intro</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/methodology">Methodology</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/strategies">Withdrawal Approaches</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/returns">Asset Returns</NavDropdown.Item>
              </NavDropdown>

              {!logInState && (
                <NavDropdown title="Manage Account" id="basic-nav-dropdown" menuVariant="dark">
                  <NavDropdown.Item as="span" onClick={handleShowLogin}>Login</NavDropdown.Item>
                  <NavDropdown.Item as="span" onClick={handleShowRegister}>Register</NavDropdown.Item>
                  <NavDropdown.Item as="span" onClick={handleShowReset}>Reset</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/terms">Terms</NavDropdown.Item>
                </NavDropdown>
              )}

              {logInState && (
                <NavDropdown title="Manage Account" id="basic-nav-dropdown-loggedin" menuVariant="dark">
                  <NavDropdown.Item as="span" onClick={handleLogout}>Logout</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/terms">Terms</NavDropdown.Item>
                  {/* You can add more options here for logged-in users */}
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Logout Modal */}
      <Modal show={showLogoutModal} onHide={handleCloseLogout}>
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>{logoutMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseLogout}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Login Modal */}
      <Modal show={showLoginModal} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm onClose={handleCloseLogin} />
        </Modal.Body>
      </Modal>

      {/* Login Modal */}
      <Modal show={showResetModal} onHide={handleCloseReset}>
        <Modal.Header closeButton>
          <Modal.Title>Reset password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResetForm onClose={handleCloseReset} />
        </Modal.Body>
      </Modal>

      {/* Register Modal */}
      <Modal show={showRegisterModal} onHide={handleCloseRegister}>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm onClose={handleCloseRegister} />
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Navigbar;
