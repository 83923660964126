import React, { useEffect, useState, Component } from 'react'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import BoxAndWhiskerPlot from "../components/barandwhisker.js"
import YieldChart from "../components/barchart.js"
import Dropdown from 'react-bootstrap/Dropdown'
import IndexChart from "../components/indexchart.js"
import '../styles/mystyles.css'
import LogoBanner from "../components/logobanner.png"
import ReturnVarianceChart from "../components/returnvariancechart.js"

const Returns = () => { 

	const API_HOST = 'http://localhost:8000';

    // const API_HOST = 'https://ukfinindbackend-eaa5c6e3a567.herokuapp.com';

    let _csrfToken = null

	// ideally need to update below so it auto links from set data for gilt coupon...

	const [requestObject, setRequestObject] = useState({'period' : 5, 'bond_coupon' : 3, 'index_bond_coupon' : 0.5, 'data_option' : 2, 'data_start_year': 1899, 'data_end_year': 2023, 'currency_set': 'USD', 'geographic_set': 'DOMESTIC'})

	const [selectedOption, setSelectedOption] = useState(5);

	const [selectedCurrency, setSelectedCurrency] = useState('USD (US Eq, US Gvmt Bonds, US CPI, 1900-2023)');

	// this is in order to control text size depending on screen size
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	const [chartData, setChartData] = useState({
			'equity_nominal': [],
			'bond_nominal': [],
			'index_bond_nominal': [],
			'cpi_nominal': [],
			'equity_nominal_avg': 0.0,
			'bond_nominal_avg': 0.0,
			'index_bond_nominal_avg': 0.0,
			'cpi_nominal_avg': 0.0,
			'equity_real': [],
			'bond_real': [],
			'index_bond_real': [],
			'equity_real_avg': 0.0,
			'bond_real_avg': 0.0,
			'index_bond_real_avg': 0.0,
	});

	const [chartData2, setChartData2] = useState({
		'equity_nominal': [],
		'bond_nominal': [],
		'index_bond_nominal': [],
		'cpi_nominal': [],
		'equity_nominal_avg': 0.0,
		'bond_nominal_avg': 0.0,
		'index_bond_nominal_avg': 0.0,
		'cpi_nominal_avg': 0.0,
		'equity_real': [],
		'bond_real': [],
		'index_bond_real': [],
		'equity_real_avg': 0.0,
		'bond_real_avg': 0.0,
		'index_bond_real_avg': 0.0,
	});

	const [chartData3, setChartData3] = useState({
		'index_bond_forward_select' : [],
        'bond_forward_select' : [],
        'forward_chart_labels' : [],
	});

	const [chartData4, setChartData4] = useState({
		'five' : [],
        'ten' : [],
        'twenty' : [],
        'thirty' : [],
	});

	const [chartData5, setChartData5] = useState({
		'equity' : [],
		'bond' : [],
		'years' : [],
	});

	const [chartData6, setChartData6] = useState({
		'one' : [],
		'ten' : [],
		'twenty' : [],
	})

    const [updatedate, setUpdatedate] = useState();

	const handleSelectChange = (eventKey) => {
		setSelectedOption(eventKey);
		setRequestObject({...requestObject,['period']:parseInt(eventKey)});
	};

	const handleCurrencyChange = (eventKey) => {
		if (eventKey == 1) {setSelectedCurrency('GBP (Global Eq, UK Gvmt Bonds, UK CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'GBP', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})} 
		else if (eventKey == 2) {setSelectedCurrency('USD (US Eq, US Gvmt Bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})}
		else if (eventKey == 3) {setSelectedCurrency('USD (US Eq, US Gvmt Bonds, US CPI, 1870-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1870, ['data_option']:parseInt(eventKey)})}
		else {setSelectedCurrency('USD (Global EQ, US Gvmt Bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})};
	};

	useEffect(() => {
		handleSubmit()
	}, []);

	useEffect(() => {
		handleSubmit()
	}, [requestObject]);

    const handleSubmit = async () => {
		try {
		// if (_csrfToken === null) {_csrfToken = await getCsrfTokenn(API_HOST)}
		// const response = await fetch(`${API_HOST}/api/historics/`, {
		const response = await fetch('/api/historics/', {
			method: 'POST',
		//   headers: ({'X-CSRFToken': _csrfToken}),
		//   credentials: 'include',
		  body: JSON.stringify(requestObject),
	});
		const data = await response.json();

		setChartData6({
			'one': data.returnvariance.one,
			'ten': data.returnvariance.ten,
			'twenty': data.returnvariance.twenty,
		})

		setChartData({
			'equity_nominal': data.deciles_equity_nominal_1,
			'bond_nominal': data.deciles_bond_nominal_1,
			'index_bond_nominal': data.deciles_index_bond_nominal_1,
			'cpi_nominal': data.deciles_cpi_change_1,
			'equity_nominal_avg': data.avg_equity_nominal_1,
			'bond_nominal_avg': data.avg_bond_nominal_1,
			'index_bond_nominal_avg': data.avg_index_bond_nominal_1,
			'cpi_nominal_avg': data.avg_cpi_change_1,
			'equity_real': data.deciles_equity_real_1,
			'bond_real': data.deciles_bond_real_1,
			'index_bond_real': data.deciles_index_bond_real_1,
			'equity_real_avg': data.avg_equity_real_1,
			'bond_real_avg': data.avg_bond_real_1,
			'index_bond_real_avg': data.avg_index_bond_real_1,
		});

		setChartData2({
			'equity_nominal': data.deciles_equity_nominal_5,
			'bond_nominal': data.deciles_bond_nominal_5,
			'index_bond_nominal': data.deciles_index_bond_nominal_5,
			'cpi_nominal': data.deciles_cpi_change_5,
			'equity_nominal_avg': data.avg_equity_nominal_5,
			'bond_nominal_avg': data.avg_bond_nominal_5,
			'index_bond_nominal_avg': data.avg_index_bond_nominal_5,
			'cpi_nominal_avg': data.avg_cpi_change_5,
			'equity_real': data.deciles_equity_real_5,
			'bond_real': data.deciles_bond_real_5,
			'index_bond_real': data.deciles_index_bond_real_5,
			'equity_real_avg': data.avg_equity_real_5,
			'bond_real_avg': data.avg_bond_real_5,
			'index_bond_real_avg': data.avg_index_bond_real_5,
		});

		setChartData3({
			'index_bond_forward_select' : data.index_bond_forward_select,
			'bond_forward_select' : data.bond_forward_select,
			'index_bond_forward_select_us' : data.index_bond_forward_select_us,
			'bond_forward_select_us' : data.bond_forward_select_us,
			'forward_chart_labels' : data.forward_chart_labels,
		});

		setChartData4({
			'five' : data.five,
			'ten' : data.ten,
			'twenty' : data.twenty,
			'thirty' : data.thirty,
		});

		setChartData5({
			'equity' : data.equity_chart,
			'bond' : data.bond_chart,
			'years' : data.years
		})

		setUpdatedate(data.update_date.toString().slice(0, -9));

		} catch (error) {console.log(error)};

	  };



	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
            	<title>Retire Smart Calc - Nominal And Real Historic Returns On Equities And Government Bonds</title>
            	<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/returns"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
				
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>



        	<div className="row">
			<div className="col-sm-2"></div>
          	<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
			  {/* <h1 className="display-5">Retire Smart Calc</h1> */}
			  {isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
				<p className="lead"></p>
				{/* <hr className="my-4"></hr> */}
				<h2 className="lead">Historic nominal and real returns on equities vs. bonds:</h2>
				<hr className="my-1"></hr>
				<p className={isSmallScreen ? 'small' : ''}>The first interactive chart below allows you to see the range of historic returns (up to end 2023) from equities and conventional bonds (10 year government bond) both in nominal and real terms (i.e. adjusted for inflation). The second chart shows the real term index growth of equites vs. conventional bonds for the selected data-set. The third chart shows the impact of different asset mix combinations of equities and conventional bonds on historic real returns vs. volatility (standard deviation) of real returns over different holding periods for the selected data-set (with annual rebalancing of the target asset mix during those holding periods).</p>


			<Dropdown onSelect={handleSelectChange}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				Choose a time period
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item eventKey="3">3 years</Dropdown.Item>
				<Dropdown.Item eventKey="5">5 years</Dropdown.Item>
				<Dropdown.Item eventKey="10">10 years</Dropdown.Item>
				<Dropdown.Item eventKey="15">15 years</Dropdown.Item>
				<Dropdown.Item eventKey="20">20 years</Dropdown.Item>
				<Dropdown.Item eventKey="25">25 years</Dropdown.Item>
			</Dropdown.Menu>
			</Dropdown>
			<div className="mt-2"></div>
			<Dropdown onSelect={handleCurrencyChange}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				Choose data set
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{/* <Dropdown.Item eventKey="1">GBP (Global equities in GBP, UK Gvmt Bonds, UK CPI)</Dropdown.Item> */}
				<Dropdown.Item eventKey="2">USD (US equities in USD, US Gvmt Bonds, US CPI 1900-2023)</Dropdown.Item>
				<Dropdown.Item eventKey="3">USD (US equities in USD, US Gvmt Bonds, US CPI 1870-2023)</Dropdown.Item>
				<Dropdown.Item eventKey="4">USD (Global equities in USD, US Gvmt Bonds, US CPI 1900-2023)</Dropdown.Item>
				<Dropdown.Item eventKey="1">GBP (Global equities in GBP, UK Gvmt Bonds, UK CPI 1900-2023)</Dropdown.Item>
			</Dropdown.Menu>
			</Dropdown>
			{/* </div>
			</div> */}

			<br></br>
			<h6>{selectedOption} year historic asset class returns ({selectedCurrency}, by distribution quartile):</h6>
			<div className="row">
			<div className="col-sm-1"></div>
			<div className="col-sm-10 mt-2">
			<BoxAndWhiskerPlot data={chartData2}/>
			{/* <p className="fw-lighter small">Note: the mid point in each bar shows the average return rather than the median.</p> */}
			</div>
			<div className="col-sm-1"></div>
			</div>

			<br></br>
			<h6>Accumulated historic real returns by asset class ({selectedCurrency}):</h6>	
			<div className="row">
			<div className="col-sm-1"></div>
			<div className="col-sm-10 mt-2">		
			<IndexChart data={chartData5}/>
			</div>
			<div className="col-sm-1"></div>
			</div>

			<h6>Average real returns and volatility of returns by asset class mix and holding period (with annual rebalancing) ({selectedCurrency}):</h6>	
			<div className="row">
			<div className="col-sm-1"></div>
			<div className="col-sm-10 mt-2">		
			<ReturnVarianceChart data={chartData6}/>
			</div>
			<div className="col-sm-1"></div>
			</div>			

			<br></br>
			<h2 className="lead">Forward looking bond returns:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>The chart below shows the current market yields that are used in the simulation calculator when 'current market yields for bonds, historic data for equities' is selected as a back-testing dataset. The expected return will be the market yield if the bond is held to maturity. Note inflation linked bonds can trade (and have traded) at market yields that will return less than inflation. Long dated bonds (both conventional and inflation linked) can be subject to significant short term price movements (positive and negative) as market yields change.</p>
			<h6>Current UK & US government bond market yields (%):</h6>
			<div className="row">
			<div className="col-sm-1"></div>
			<div className="col-sm-10 mt-2">
			<YieldChart data={chartData4}/>
			<p className="fw-lighter small">Note: inflation linked yields are 'premium' or 'discount' in addition to returning the rate of inflation. Market data as of: {updatedate}</p>
			</div>
			<div className="col-sm-1"></div>
			</div>
			{/* this is blanked out until i) integrate potential portfolio growth or average additional payout as $ + bonus strategy into it (this would make for a useful dotted line) and ii) create a view to automate its production (and a hidden page to run it) */}
			{/* <h2 className="lead">Asset mix strategy considerations:</h2>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>There are range of factors to be considered in optimising asset mix (between equities, conventional bonds and index linked bonds): time horizon, desire for retirement income certainty vs. potential portfolio growth, tolerance for portfolio value volatility and distribution of withdrawals. For example, the chart below shows how the trade-off between the maximum back-tested safe withdrawal rate, asset-mix (index linked bonds vs. equity) and average portfolio value at the end of the simulation varies with time horizon.</p>
			<h6>Safe withdrawal rate by asset mix and simulation period (USD market yields for ILB, historic data for US equities):</h6>	
			<div className="row">
			<div className="col-sm-1"></div>
			<div className="col-sm-10 mt-2">		
			<SWRAssetMixChart data={chartData6}/>
			<p className="fw-lighter small">Note: market data for inflation linked bond yields as of 2024-06-11. </p>
			</div>
			<div className="col-sm-1"></div>
			</div>	 */}

			</div> 
			<div className="col-sm-2"></div>
			</div>
		</Container>
	); 
	}; 

	export default Returns;
	 
	  
	  
	  
	  