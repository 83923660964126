import { useAuthStore } from '../store/auth';
import axios from './axios';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useSharedData } from '../components/setdata.js';
import useLogin from '../utils/login';


export const register = async (username, password, password2) => {

    try {
        const { data } = await axios.post('register/', {
            username,
            password,
            password2,
        });
        // await login(username, password);
        return { data, error: null };
    } catch (error) {
        return {
            data: null,
            error: error.response.data || 'Something went wrong',
        };
    }
};

export const logoutwithoutreset = async () => {
    const response = await axios.post('logout/', {});
    useAuthStore.getState().setUser(null);
    };


export const setAuthUser = (access_token, refresh_token) => {
// export const setAuthUser = (access_token) => {
        Cookies.set('access_token', access_token, {
        expires: 1,
        secure: true,
    });

    Cookies.set('refresh_token', refresh_token, {
        expires: 7,
        secure: true,
    });

    const user = jwt_decode(access_token) ?? null;

    if (user) {
        useAuthStore.getState().setUser(user);
    }
    useAuthStore.getState().setLoading(false);
};

export const setAuthUserToStore = (access_token) => {    
        const user = jwt_decode(access_token) ?? null;
    
        if (user) {
            useAuthStore.getState().setUser(user);
        }
        useAuthStore.getState().setLoading(false);
    };

export const getRefreshToken = async () => {
    try {
        // Make the request with { withCredentials: true } to send HTTP-only cookies
        // const response = await axios.post('token/refresh/', {}, { withCredentials: true }); 
        console.log('Trying to refresh token');
        const response = await axios.post('token/refresh/', {});
        console.log(response.data);
        // The server will automatically handle the refresh token from the cookie
        return response.data;
    } catch (error) {
        console.error('Error refreshing access token:', error.response?.data || error.message);
        return null;
    }
};

export const isAccessTokenExpired = (accessToken) => {
    try {
        const decodedToken = jwt_decode(accessToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true; // Token is invalid or expired
    }
};
