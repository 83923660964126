import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 

function IndexChart(props) {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height

  useEffect(() => {
    const updateChartHeight = () => {
      // Calculate the height based on screen width
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * 0.3; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);


  var my_font_size = isSmallScreen ? 8 : 12; 

  const chartData = {
    labels: props.data.years,
    datasets: [
      {
        label: 'Equity Index (Total Return)',
        data: props.data.equity,
        fill: false,
        borderColor: 'rgba(144, 238, 144, 0.5)',
        backgroundColor: 'rgba(144, 238, 144, 0.5)',
        borderWidth: 2,
      },
      {
        label: 'Bond (Gvmt Conventional 10y)',
        data: props.data.bond,
        fill: false,
        borderColor: 'rgba(173, 216, 230, 0.5)',
        backgroundColor: 'rgba(173, 216, 230, 0.5)',
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins : {

        legend: {
          labels: {
            color: 'white',
            font: {size : my_font_size,},
          },
        },

        title: {
          display: false,
          color: 'white',
          font: {
            size: 14, // Set the font size for the title
          },
          text: 'Chart title',
        },
    },
    scales: {
        x: {
          grid: {
            color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
          },
          ticks: {
            color: 'white', // Set x-axis ticks color to white
            font: {size : my_font_size,},
          },
          title: {
            display: true,
            color: 'white',
            text: 'Year',
            font: {
              size: my_font_size,}, 
          },
          type: 'category',
          // labels: labels,
        },
        y: {
          type: 'logarithmic',
          base: 1,
          title: {
            display: true,
            color: 'white',
            text: '1900 = 1',
            font: {
              size: my_font_size, 
            },},
          ticks: {
                color: 'white',
                callback: (value, index, values) => {
                  const allowedValues = [1, 10, 100, 1000, 10000];
                  if (allowedValues.includes(value)) {
                    return value;
                  }
                  return '';
                },
                font: {size : my_font_size,},
              },
          grid: {
              color: (context) => {
                const allowedValues = [1, 10, 100, 1000, 10000];
                return allowedValues.includes(context.tick.value) ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0)';
              },
            },
        },
      },
    };

  return (
    // disabled as doesn't always work properly
    <div>
    {isSmallScreen ? (
      <div style={{ width: '100%', height: '300px' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    ) : (
      // <div style={{ width: '100%', height: `${chartHeight}px` }}>
      <div style={{ width: '100%', height: '400px' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    )}
    </div>
  );
};


export default IndexChart;


