import React, { useEffect, useState, Component } from 'react'
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import LogoBanner from "../components/logobanner.png"


import '../styles/mystyles.css'

function About() {

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Define the structured data object which feeds the thing inside the script tag below.  Apparently convention to include the script tag even though not strictly necessary.
	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	return (
	<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>

	<Helmet>
		<title>Retire Smart Calc - Introduction, Explanation of Key Features, Methodology and Data Sources</title>
		{/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
		<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
		<link rel="canonical" href="https://www.retiresmartcalc.com"></link>
		<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity. " />

		<script type="application/ld+json">
      	{JSON.stringify(webpageData)}
    	</script>
	</Helmet>
	
	<div className="row">
	<div className="col-sm-2"></div>
	  	<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			{/* <h2 className="lead">Key features:</h2>
			<hr className="my-1"></hr>
			{/* <li className={isSmallScreen ? 'small' : ''}>Plan contribution and withdrawal schedules across lifecycle</li> */}
			{/* <li className={isSmallScreen ? 'small' : ''}>Calculate and back-test safe withdrawal rates (SWR) for a retirement portfolio</li> */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Select between USD and GBP base currency data sets (for US and UK based investors respectively)</li>   */}
			{/* <li className={isSmallScreen ? 'small' : ''}>Incorporate inflation linked bonds as well as conventional bonds and equities in portfolio allocation</li>  */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Find optimal portfolio asset mix to balance portfolio stability vs. potential returns</li>             */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Compare drawdown vs. annuity based retirement income strategies</li>  */}
            {/* <li className={isSmallScreen ? 'small' : ''}>Examine constant vs. flexible withdrawal strategies</li> */}
            {/* <br></br> */}
            <h2 className="lead">Approach & methodology:</h2>
            <hr className="my-1"></hr>
			{/* <p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc is a tool designed to help people manage both building up and spending down a retirement portfolio across the different phases of their lifecycle. This includes optimising both the level of contributions that they make to the portfolio and the level and timing of retirement income they take from that portfolio, as well as the portfolio’s asset allocation mix.</p> */}
            <p className={isSmallScreen ? 'small' : ''}>At its core is a model that uses the <a className="text-info" href="https://en.wikipedia.org/wiki/William_Bengen" target="_blank">Bengen</a> or exploratory simulation approach (named after William Bengen who devised his 4% rule from it) to allow the user to test whether a retirement portfolio of a given size should last for a given number of years for a given schedule of annual withdrawals. The Bengen approach uses historical US equity, US bond and US inflation data to simulate how a retirement portfolio strategy would have fared had retirement started onwards from 1925, then from 1926, then from 1927, and so on. It produces a distribution of portfolio values both throughout and at the end of the simulation. It calculates in what portion of back-testing cycles would the portfolio have lasted until the end of the simulation period and, conversely, in what portion of cycles it would have been depleted before the end of the simulation period.</p>
			<p className={isSmallScreen ? 'small' : ''}>The benefit of using this approach with historic data is it purposely avoids that need for ‘crystal ball’ judgement on future asset returns. However the historic data that is used needs to both go back far enough and be broad enough to cover the range of economic and political events that can influence asset returns.  Plans that have been back-tested through a sufficiently long set of historic data will rest on a conservative scenario of asset returns (i.e. the least positive cases in the historical data). This is safer than planning on the basis of average asset returns, because in 50% or more of cases, the investor does not achieve the average return. The tool will help with regular (e.g. annual) assessments on how plans are progressing and whether changes should be considered e.g. if actual market returns are better than back-test scenarios then it may be possible to reduce contribution levels, de-risk the asset allocation (when risk is no longer required to generate returns) and/or increase the withdrawal levels.</p>
			<p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc extends on the Bengen approach in a number of ways:</p>
			<p className={isSmallScreen ? 'small' : ''}>Firstly it allows the user to use a longer set of historic data going back earlier than 1925 and extending through to end 2023.  This improves the robustness of the back-testing by incorporating two extended periods of poor market returns (in real terms) - the first during the 1910’s and the First World War, the second during the 1970’s and the energy crisis.  Both these periods experienced high inflation which negatively impacted real returns.  The user can also choose circular or linear exploratory simulation.  Circular exploratory simulation extends the number of back-testing cycles by allowing those cycles that begin with a start year towards the end of the historic data set to ‘circle’ round and also use data from the beginning of the historic data set to complete the cycle.</p>
			<p className={isSmallScreen ? 'small' : ''}>Secondly, thanks of having more back-testing cycles, it offers extended simulations that can cover an entire lifecycle and include a contributing (accumulation) phase before the withdrawal (decumulation) phase.  The tool can be used to test the required level of contribution to achieve a target retirement portfolio size and subsequent withdrawal schedule, as well as test a schedule of withdrawals for a given retirement portfolio size.</p>
			<p className={isSmallScreen ? 'small' : ''}>Thirdly it allows the user to choose whether to use US domestic or Global equity returns in the back-testing data.  Again this can improve the robustness of back-testing given that US market returns over the past 120 years can be considered ‘exceptional’ in a comparison with other markets. Global equity returns, particularly during the years 1900-1945, may better incorporate the impact of war, uncontrolled inflation and political upheaval on market returns. UK based users also have the option of selecting a data-set that consists of Global equity returns in GBP terms, UK bonds and UK inflation.</p>
			<p className={isSmallScreen ? 'small' : ''}>Fourthly it gives the user the option to i) use an entirely historic back-testing data-set or ii) to use current forward-looking market yields to simulate returns on the bond portion of the portfolio together with historic data to simulate returns on the equity portion of the portfolio. The second option allows for both conventional and inflation linked government bonds to be simulated in the portfolio asset mix.  Since there is more limited historic data for inflation linked bonds (the US and UK governments only started issuing them in the 1980’s), it is not possible to include them in an entirely historic back-testing data-set. The forward-looking market yield data in RetireSmartCalc is updated weekly - see beneath the chart of bond market yields on the SWR Observations page to check when the current data is from.</p>
			<p className={isSmallScreen ? 'small' : ''}>Fifthly it allows the user the option to simulate purchasing a fixed (immediate) annuity in their retirement portfolio, either at the beginning of the simulation cycle or during the cycle after a number of years of retirement. This, together with varying the asset mix between equities, conventional bonds and inflation linked bonds, allows the user to compare and contrast different portfolio strategies looking at the amounts that can be safely withdrawn each year (the safe withdrawal rate) versus the potential for portfolio growth that can provide for increased future withdrawals or a larger bequest at the end of the simulation cycle. The user will need to research current market annuity prices and input these into the calculator, as these do not yet feed automatically into RetireSmartCalc.</p>
			<p className={isSmallScreen ? 'small' : ''}>Sixthly it can incorporate the impact of taxes and portfolio management fees. Taxes are deducted according to fund type in asset portfolio. For general funds, taxes are taken from returns. For tax deferred funds, taxes are taken on withdrawals. For tax exempt funds, no taxes are taken. The simulation assumes that general funds are drawn down first, then tax-deferred funds, and finally tax-exempt funds. Simulation results are shown net of taxes.</p>
			<p className={isSmallScreen ? 'small' : ''}>Finally it allows the user to back-test flexible, rather than just fixed, withdrawal strategies.  This includes flexing the withdrawal level up or down in response to market conditions or taking an extra bonus withdrawal when and if the investment portfolio has grown in response to positive market returns such that the portfolio becomes larger than it needs to be to meet all future scheduled withdrawals. Other flexible withdrawal options offered by the tool include the Yale Endowment and Vanguard Dynamic Spending approaches.</p>
			<p className={isSmallScreen ? 'small' : ''}>All the values in the simulation calculator are inflation adjusted and are shown in current value terms.</p>
            <h2 className="lead">Disclaimer:</h2>
            <hr className="my-1"></hr>
            <p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc, its calculation tools, content, etc do not constitute investment advice. RetireSmartCalc is designed as an educational aid for experienced investors who are familiar with financial and investing concepts and who understand that future investment returns may be different from historic returns. Seek advice from an qualified financial advisor or planner if you are unsure. See terms.</p>
            <h2 className="lead">Data sources:</h2>
            <hr className="my-1"></hr>          
            <p className={isSmallScreen ? 'small' : ''}>Different data sets can be selected in the simulation calculator. US data sets use either US or global equity returns together with US bond returns and US CPI data. UK data sets use global equity returns (in GBP) together with UK bond returns and UK CPI data.</p>   
			<p className={isSmallScreen ? 'small' : ''}>US equities are represented by S&P 500 total returns data from Robert Shiller. Historic US bond yields and US CPI data are also from <a className="text-info" href="https://www.multpl.com" target="_blank">Robert Shiller</a>. Historic UK bond yields and UK CPI data from the <a className="text-info" href="https://www.bankofengland.co.uk/statistics/research-datasets" target="_blank">Bank of England</a>.  Global equities up until 1960 are represented by a composite total return benchmark including US, UK, France and Germany (representing the largest four equity markets in 1900 - US data is from Robert Shiller, UK data is from Barclays Equity Gilt study, FR data is from David Le Bris and Pierre-Cyrille Hautcouer and DE data is from Ulrich Ronge). Global equities from 1960-1979 uses data from Ibbotson, Carr and Robinson and includes 19 countries (covering major markets in North America, Western Europe and the Far East). Global equities from 1980 is represented by a composite total return benchmark combining US S&P 500 and MSCI World ex-US indicies. Current US market bond yields can be found at <a className="text-info" href="https://fred.stlouisfed.org/" target="_blank">St Louis Federal Reserve</a> and UK market bond yields can be found at <a className="text-info" href="https://www.bankofengland.co.uk/statistics/yield-curves" target="_blank">Bank of England</a>.</p>
			<p className={isSmallScreen ? 'small' : ''}>The historic data set used by RetireSmartCalc can be <a href="/downloadhistoricdata" download>downloaded here</a> and the forward data set can be <a href="/downloadforwarddata" download>downloaded here</a>. The composition and calculation of the historic global equity return index can be <a href="/downloaddata" download>downloaded here</a>. Mortality data is taken from Idzorek and Kaplan: Lifetime Financial Advice and can be <a href="/downloadmortalitydata" download>downloaded here</a>.</p>
			{/* <p className={isSmallScreen ? 'small' : ''}>The model and API documentation can be <a href="/downloaddocumentation" download>downloaded here</a>.</p> */}
			<h2 className="lead">Get in touch:</h2>
            <hr className="my-1"></hr>
            <p className={isSmallScreen ? 'small' : ''}>This is a beta testing release. Please get in touch with any feedback or improvement ideas: contact@retiresmartcalc.com</p>
		</div>
	</div>
	</Container>
	);
}; 

export default About;

