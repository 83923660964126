import React, { useEffect, useState, Component } from 'react'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// import getCsrfTokenn from "../components/setCSRF.js"
import BoxAndWhiskerPlot from "../components/barandwhisker.js"
import YieldChart from "../components/barchart.js"
import Dropdown from 'react-bootstrap/Dropdown'
import IndexChart from "../components/indexchart.js"
import '../styles/mystyles.css'
import LogoBanner from "../components/logobanner.png"


const Learning = () => { 

const API_HOST = 'http://localhost:8000';

let _csrfToken = null

// ideally need to update below so it auto links from set data for gilt coupon...

const [requestObject, setRequestObject] = useState({'period' : 5, 'bond_coupon' : 3, 'index_bond_coupon' : 0.5, 'data_option' : 2, 'data_start_year': 1899, 'data_end_year': 2023, 'currency_set': 'USD', 'geographic_set': 'DOMESTIC'})

const [selectedOption, setSelectedOption] = useState(5);

const [selectedCurrency, setSelectedCurrency] = useState('USD (US Eq, US Gvmt Bonds, US CPI, 1900-2023)');

// this is in order to control text size depending on screen size
const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
useEffect(() => {
	const handleResize = () => {
	setIsSmallScreen(window.innerWidth < 576);
	};
	window.addEventListener('resize', handleResize);
	return () => {
	window.removeEventListener('resize', handleResize);
	};
}, []);

const webpageData = {
	"@context": "https://schema.org",
	"@type": "WebPage",
	"name": "Retire Smart Calc",
	"logo": "https://www.retiresmartcalc.com/logo.png",
	"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
};

const [chartData, setChartData] = useState({
		'equity_nominal': [],
		'gilt_nominal': [],
		'ilb_nominal': [],
		'cpi_nominal': [],
		'equity_nominal_avg': 0.0,
		'gilt_nominal_avg': 0.0,
		'ilb_nominal_avg': 0.0,
		'cpi_nominal_avg': 0.0,
		'equity_real': [],
		'gilt_real': [],
		'ilb_real': [],
		'equity_real_avg': 0.0,
		'gilt_real_avg': 0.0,
		'ilb_real_avg': 0.0,
});

const [chartData2, setChartData2] = useState({
	'equity_nominal': [],
	'gilt_nominal': [],
	'ilb_nominal': [],
	'cpi_nominal': [],
	'equity_nominal_avg': 0.0,
	'gilt_nominal_avg': 0.0,
	'ilb_nominal_avg': 0.0,
	'cpi_nominal_avg': 0.0,
	'equity_real': [],
	'gilt_real': [],
	'ilb_real': [],
	'equity_real_avg': 0.0,
	'gilt_real_avg': 0.0,
	'ilb_real_avg': 0.0,
});

const [chartData3, setChartData3] = useState({
	'ilb_forward_select' : [],
	'gilt_forward_select' : [],
	'forward_chart_labels' : [],
});

const [chartData4, setChartData4] = useState({
	'five' : [],
	'ten' : [],
	'twenty' : [],
	'thirty' : [],
});

const [chartData5, setChartData5] = useState({
	'equity' : [],
	'bond' : [],
	'years' : [],
});

const [updatedate, setUpdatedate] = useState();

const handleSelectChange = (eventKey) => {
	setSelectedOption(eventKey);
	setRequestObject({...requestObject,['period']:parseInt(eventKey)});
};

const handleCurrencyChange = (eventKey) => {
	if (eventKey == 1) {setSelectedCurrency('GBP (Global Eq, UK Gvmt Bonds, UK CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'GBP', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})} 
	else if (eventKey == 2) {setSelectedCurrency('USD (US Eq, US Gvmt Bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})}
	else {setSelectedCurrency('USD (Global EQ, US Gvmt Bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})};
};

useEffect(() => {
	handleSubmit()
}, []);

useEffect(() => {
	handleSubmit()
}, [requestObject]);

const handleSubmit = async () => {
	try {
	// if (_csrfToken === null) {_csrfToken = await getCsrfTokenn(API_HOST)}
	// const response = await fetch(`${API_HOST}/api/historics/`, {
	const response = await fetch('/api/historics/', {
		method: 'POST',
	//   headers: ({'X-CSRFToken': _csrfToken}),
	//   credentials: 'include',
	  body: JSON.stringify(requestObject),
});
	const data = await response.json();

	setChartData({
		'equity_nominal': data.deciles_equity_nominal_1,
		'gilt_nominal': data.deciles_gilt_nominal_1,
		'ilb_nominal': data.deciles_ilb_nominal_1,
		'cpi_nominal': data.deciles_cpi_change_1,
		'equity_nominal_avg': data.avg_equity_nominal_1,
		'gilt_nominal_avg': data.avg_gilt_nominal_1,
		'ilb_nominal_avg': data.avg_ilb_nominal_1,
		'cpi_nominal_avg': data.avg_cpi_change_1,
		'equity_real': data.deciles_equity_real_1,
		'gilt_real': data.deciles_gilt_real_1,
		'ilb_real': data.deciles_ilb_real_1,
		'equity_real_avg': data.avg_equity_real_1,
		'gilt_real_avg': data.avg_gilt_real_1,
		'ilb_real_avg': data.avg_ilb_real_1,
	});

	setChartData2({
		'equity_nominal': data.deciles_equity_nominal_5,
		'gilt_nominal': data.deciles_gilt_nominal_5,
		'ilb_nominal': data.deciles_ilb_nominal_5,
		'cpi_nominal': data.deciles_cpi_change_5,
		'equity_nominal_avg': data.avg_equity_nominal_5,
		'gilt_nominal_avg': data.avg_gilt_nominal_5,
		'ilb_nominal_avg': data.avg_ilb_nominal_5,
		'cpi_nominal_avg': data.avg_cpi_change_5,
		'equity_real': data.deciles_equity_real_5,
		'gilt_real': data.deciles_gilt_real_5,
		'ilb_real': data.deciles_ilb_real_5,
		'equity_real_avg': data.avg_equity_real_5,
		'gilt_real_avg': data.avg_gilt_real_5,
		'ilb_real_avg': data.avg_ilb_real_5,
	});

	setChartData3({
		'ilb_forward_select' : data.ilb_forward_select,
		'gilt_forward_select' : data.gilt_forward_select,
		'ilb_forward_select_us' : data.ilb_forward_select_us,
		'gilt_forward_select_us' : data.gilt_forward_select_us,
		'forward_chart_labels' : data.forward_chart_labels,
	});

	setChartData4({
		'five' : data.five,
		'ten' : data.ten,
		'twenty' : data.twenty,
		'thirty' : data.thirty,
	});

	setChartData5({
		'equity' : data.equity_chart,
		'bond' : data.bond_chart,
		'years' : data.years
	})

	setUpdatedate(data.update_date.toString().slice(0, -9));

	} catch (error) {console.log(error)};

  	};

	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
				<title>Retire Smart Calc - Observations On Safe Withdrawal Rates From Back-testing And Strategies To Improve</title>
				<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/observations"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
				
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>



        	<div className="row">
			<div className="col-sm-2"></div>
          	<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
			{/* <h1 className="display-5">Retire Smart Calc</h1> */}
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			<p className="lead">Ways to optimise the Safe Withdrawal Rate (SWR):</p>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>	
			Bengen's original analysis, and the subsequent <a className="text-info" href="https://en.wikipedia.org/wiki/Trinity_study" target="_blank">Trinity</a> study, suggested a safe withdrawal rate (SWR) of 4% for a 30 year retirement with a portfolio predominately invested in equities. That analysis used market returns dating up until 1995 and the last 30 year scenario cycle in the analysis began in 1965.  However, several years in the late 1960's (especially 1966) proved to be the worse-in-a-century to begin retirement in as investors suffered high 'sequence' risk - the real value of both equities and conventional bonds were ravaged by high inflation and heavy market price declines early on in the scenario cycle. As such, the back-tested SWR with a zero failure rate using a USD data-set that incorporates retirement scenarios starting in the late 1960's / early 1970's is less than 4% (on an 80:20 equity : conventional bond portfolio). 
			</p>
			<p className={isSmallScreen ? 'small' : ''}>	
			Further, since 1900, US equity returns have outperformed global returns in USD terms. Using global equities rather than US equities in the back-testing data set may further reduce the SWR (on an 80:20 equity:conventional bond portfolio).
			</p>
			<p className={isSmallScreen ? 'small' : ''}>	
			There are several ways by which investors may target a higher safe withdrawal rate:
			<li>Accept flexibility in year to year withdrawal levels</li>
			<li>Examine the case for including index linked government bonds in the portfolio asset mix</li>
			<li>Examine the case for a hybrid strategy of initially using a drawdown portfolio and later purchasing an annuity to provide retirement income</li>
			</p>
			<p className="lead">Withdrawal flexibility and SWR:</p>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>	
			By accepting the risk of lower withdrawal levels (in real-terms) as retirement progresses, a higher starting withdrawal level is possible for a given probability of portfolio exhaustion. The simulation calculator allows for different withdrawal strategies to be tested:
			<li>Constant: fixed withdrawal amount in real terms (e.g. the level is increased by inflation each year) taken each year irrespective of market performance.</li>
			<li>Constant with bonus: two components - a 'constant' withdrawal component (increased by inflation each year) and a 'bonus' withdrawal component that is taken as and if the portfolio value grows above the safe funding level. The safe funding level for each year through the simulation = updated withdrawal amount / the max backtested safe withdrawal rate for year through simulation (also known as a dynamic SWR). The simulator calculates this level and uses it in the bonus withdrawal calculation. The dynamic SWR and safe funding level curves for the user's portfolio can be seen from the safe withdrawal analysis tab at the bottom of the calculator screen.</li>
			<li>Flex: withdrawal amount each year is flexed up or down (in real terms) by user defined percentages in response to how the portfolio value develops.  It uses the same safe funding level concept as above. If the portfolio value grows above the safe funding level, the withdrawal level flexs up.  Vice versa, if the portfolio value falls below the safe funding level, the withdrawal level flexes down.</li>
			<li>Fixed percentage withdrawal: withdrawal amount is a set percentage of the portfolio value each year - as such the portfolio can not be exhausted but year to year withdrawal levels can be (very) volatile. There is the option to add a minimum withdrawal level.</li>	
			<li>Yale endowment: withdrawal amount each year is based on formula = x * previous year's withdrawal + (1 - x) * set percentage of the portfolio value that year, where x is a fixed weight. This approach is used by Yale University to manage withdrawals from their endownment fund.  Depending on the weighting of x, it provides more withdrawal level stability than the fixed percentage withdrawal approach.</li>
			<li>Vanguard dynamic spending: withdrawal amount based on a set percentage of portfolio value but with a cap and a floor on the maximum real-term change from the previous year's withdrawal level.  The cap and floor can be changed by the investor. Futher explanation can be found <a className="text-info" href="https://investor.vanguard.com/investor-resources-education/article/retirement-withdrawal-strategies" target="_blank">here</a>.</li>
			<li>Bogleheads variable percentage withdrawal: withdrawal amount based on a table of percentage withdrawal rates adjusted for age and asset mix. Each year the percentage withdrawal rate in the table increases. The table and further explanation can be found <a className="text-info" href="https://www.bogleheads.org/wiki/Variable_percentage_withdrawal" target="_blank">here</a>.</li>
			</p>

			<p className="lead">Index linked bonds and SWR:</p>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>Index linked bonds are an alternative to using conventional bonds in the portfolio. They may provide a better portfolio hedge against periods of unexpectedly high inflation than conventional bonds. How the inclusion of index linked bonds in the portfolio changes the possible SWR depends on the current market yield on those bonds (or the real return premium the bonds offer over actual inflation - this premium varies with time and can be negatives as well as positive).</p>

			<h6>Current UK & US government bond market yields (%):</h6>
			<div className="row">
			<div className="col-sm-1"></div>
			<div className="col-sm-10 mt-2">
			<YieldChart data={chartData4}/>
			<p className="fw-lighter small">Note: inflation linked yields are 'premium' or 'discount' in addition to returning the rate of inflation. Market data as of: {updatedate}</p>
			</div>
			<div className="col-sm-1"></div>
			</div>

			<p className="lead">Hybrid drawdown portfolio - annuity strategy and SWR:</p>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>Purchasing an annuity gives an investor a guaranteed stream of income for the rest of their life, potentially reducing longevity risk and the length of time their investment portfolio needs to last. However locking into an annuity early in retirement gives up the opportunity for extended exposure to equity market returns. A hybrid or flex approach sees the investor initially take retirement income withdrawals from a market invested portfolio and then purchase an annuity with this portfolio later on. Whether this strategy may give an SWR improvement or not will depend on the market price for an annuity (itself a reflection of market bond yields). The investor also runs the risk that the cost of the annuity rises between initial retirement and the time of purchase.</p>

			{/* <p className="lead">SWR back-testing with historic GBP (UK government bonds, global equities) vs USD data set (US government bonds, US equities)</p>
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>		
			Over the back-testing data period, real returns on UK government conventional bonds have been lower than US government conventional bonds as UK CPI has been higher relative to bond yields. Further, real-returns in GBP terms on global equities have been, on average, lower than real-returns in USD terms on US equities. As a result, the simulation calculator may give a lower SWR for a GBP backtesting data set than for a USD backtesting data set.
			</p>			 */}
			
			</div> 
			<div className="col-sm-2"></div>
		</div>
		</Container>
	); 
	}; 

	export default Learning;
	 
	  
	  
	  
	  