import React, { useEffect, useState, Component } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import LogoBanner from "../components/logobanner.png"

import '../styles/mystyles.css'

function About() {

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Define the structured data object which feeds the thing inside the script tag below.  Apparently convention to include the script tag even though not strictly necessary.
	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Backtest and optimise retirement investment and income strategy.",
	};

	return (
	<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>

	<Helmet>
		<title>Retire Smart Calc - Introduction, Key Features</title>
		<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
		<link rel="canonical" href="https://www.retiresmartcalc.com"></link>
		<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Backtest and optimise retirement investment and income strategy." />

		<script type="application/ld+json">
      	{JSON.stringify(webpageData)}
    	</script>
	</Helmet>
	
	{/* <p>Login status: {isLoggedIn}</p> */}
	<div className="row">
	<div className="col-sm-2"></div>
	  	<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			<h2 className="lead">Key features:</h2>
			<hr className="my-1"></hr>
			<li className={isSmallScreen ? 'small' : ''}>Design a retirement contribution, investment strategy and retirement income plan</li>
			<li className={isSmallScreen ? 'small' : ''}>Plan during either accumulation (pre-retirement) or decumulation (retirement) lifecycle stages</li>
			<li className={isSmallScreen ? 'small' : ''}>Set year by year retirement income requirements and select between fixed vs. flexible withdrawal approaches</li>
			<li className={isSmallScreen ? 'small' : ''}>Optimise investment strategy asset mix (across equities, conventional bonds and inflation linked bonds)</li>
			<li className={isSmallScreen ? 'small' : ''}>Examine whether to use annuities to provide lifetime income in plan</li>
			<li className={isSmallScreen ? 'small' : ''}>Back-test strategy against either USD or GBP denominated data-set</li> 
			<li className={isSmallScreen ? 'small' : ''}>Optimise strategy using simulation graphics and algorithms</li> 
            <br></br>
            <h2 className="lead">Using the simulation calculator:</h2>
            <hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc is a simulation calculator that helps the user create a retirement contribution, investment and income plan and back-test this plan by simulating its performance against over a century of historic market data.</p>
			<p className={isSmallScreen ? 'small' : ''}>The simulation calculator builds a plan around the following blocks:</p>
			<h6>User profile:</h6>
			<p className={isSmallScreen ? 'small' : ''}>The user's age at the beginning (e.g. current age) and end of the simulation (e.g. 100 years old). If the simulation is for two people, use the youngest age.</p>
			<h6>Current retirement savings:</h6>
			<p className={isSmallScreen ? 'small' : ''}>The current portfolio of savings designated for retirement together with any expected state pension / social security and (defined contribution) occupational pension income.</p>
			<h6>Further retirement saving:</h6>
			<p className={isSmallScreen ? 'small' : ''}>Further savings contributions planned to be made before retirement together with any expected one-off/extra-ordinary inflows (e.g. inheritance or property sale).</p>
			<h6>Target income and withdrawal approach:</h6>
			<p className={isSmallScreen ? 'small' : ''}>Income requirement based on total of planned expenditure items through retirement including any one-off/extra-ordinary planned expenditures. Select a withdrawal approach that either delivers a specific income requirement or targets a rate of withdrawal with essential expenditure requirements setting a floor for the withdrawal amount.  The simulation calculator meets the annual income requirement by firstly using any state pension or occupational pension income and then by making an annual withdrawal from the portfolio value. See <Link to="/strategies">withdrawal approaches</Link> for explanation of how a flexible approach may allow an initially higher income level relative to retirement portfolio size. </p>
			<h6>Portfolio asset mix (including equities, conventional bonds and inflation linked bonds):</h6>
			<p className={isSmallScreen ? 'small' : ''}>The asset mix for duration of the simulation; the simulation calculator automatically rebalances the asset mix each year.  The simulation calculator can incorporate inflation linked bond in addition to conventional government bonds. Inflation linked bonds can provide better portfolio protection against unexpectedly high inflation. Equities provide an opportunity for long-term portfolio growth. See <Link to="/returns">asset returns</Link> for exhibits on historic asset returns, the impact of inflation on returns and the possible benefit of asset class diversification.</p>
			<h6>Optional annuitisation:</h6>
			<p className={isSmallScreen ? 'small' : ''}>Option to simulate purchasing a lifetime annuity out of the portfolio value to provide all or part of the income requirement at a given point into retirement simulation.  Depending on the time of purchase, this can reduce the amount required to fund retirement income through until 100+ years but at the expense of a potential legacy portfolio at the end of the simulation.</p>
			<h6>Taxes and fees:</h6>
			<p className={isSmallScreen ? 'small' : ''}>Taxes that will be deducted from returns for different fund types. Simulation shows results net of tax.</p>
			<h6>Back-test strategy against USD or GBP data-set:</h6>
			<p className={isSmallScreen ? 'small' : ''}>Choose whether to use USD (for US investors) or GBP (for UK investors) denominated dataset. Also choose whether to use historic returns or current market yields for the bond portion of portfolio and whether to use US or global equity returns. See <Link to="/methodology">methodology</Link> for more explanation on the differences.</p>
			<h6>Optimise strategy using simulation graphics and algorithms:</h6>
			<p className={isSmallScreen ? 'small' : ''}>The simulation calculator graphics show how the strategy would have performed in each back-test cycle (each one starting from a different historic year in the data-set). The simulation cycle failure rate measures the percentage of back-tests where the portfolio was exhausted before the end of the simulation.  The higher this number, the riskier the retirement investment and income strategy can be considered to be. The asset mix optimisation function iterates through different asset mix combinations until it finds the optimal mix (to either maximise expected portfolio growth or to minimise portfolio value volatility whilst keeping the simulation cycle failure rate as low as possible).</p>
            <h2 className="lead">Disclaimer:</h2>
            <hr className="my-1"></hr>
            <p className={isSmallScreen ? 'small' : ''}>RetireSmartCalc, its calculation tools, content, etc do not constitute investment advice. RetireSmartCalc is designed as an educational aid for experienced investors who are familiar with financial and investing concepts and who understand that future investment returns may be different from historic returns. Seek advice from an qualified financial advisor or planner if you are unsure. See <Link to="/terms">terms</Link>.</p>
			<h2 className="lead">Get in touch:</h2>
            <hr className="my-1"></hr>
            <p className={isSmallScreen ? 'small' : ''}>Please get in touch with any feedback or improvement ideas: contact@retiresmartcalc.com</p>
		</div>
	</div>
	</Container>
	);
}; 

export default About;

