import React, { useEffect, useState, Component } from 'react'
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; 
  
function YieldChart(props) { 

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  useEffect(() => {
    const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);

  var my_font_size = isSmallScreen ? 8 : 12; 

  const chartData = {
    labels: ['UK Conventional', 'UK Inflation Linked', 'US Conventional', 'US Inflation Linked'],

    datasets: [
      {
        label: '5 year',
        data: props.data.five,
        backgroundColor: 'rgba(255, 182, 193, 0.2)',
        borderColor: 'rgba(255, 182, 193, 1)',
        borderWidth: 1,
      },
      {
        label: '10 year',
        data: props.data.ten,
        backgroundColor: 'rgba(173, 216, 230, 0.2)',
        borderColor: 'rgba(173, 216, 230, 1)',
        borderWidth: 1,
      },
      {
        label: '20 year',
        data: props.data.twenty,
        backgroundColor: 'rgba(144, 238, 144, 0.2)',
        borderColor: 'rgba(144, 238, 144, 1)',
        borderWidth: 1,
      },
      {
        label: '30 year',
        data: props.data.thirty,
        backgroundColor: 'rgba(221, 160, 221, 0.2)',
        borderColor: 'rgba(221, 160, 221, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    // maintainAspectRatio: isLargeScreen,
    maintainAspectRatio: false,
    plugins : {
      legend: {
        labels: {
          color: 'white',
          font: {size : my_font_size,}
        },
        
      },

      title: {
        display: false,
        color: 'white',
        font: {
          size: 14, // Set the font size for the title
        },
        text: 'Chart title',
      },
  },


    scales: {
      x: {
        ticks: {
          color: 'white', // Set x-axis ticks color to white
          font: {size : my_font_size,}
        },
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
          font: {size : my_font_size,}
        },
      beginAtZero: true,
        title: {
          display: true,
          text: 'Market yield %',
          color: 'white',
          font: {size : my_font_size,}
        },
      },
    },
  };

  return ( 
    <div style = {{ width:'100%', height:'300px'}}> 
      <Bar data={chartData} options={chartOptions}/>
    </div> 
  ); 
} 
  
export default YieldChart; 
