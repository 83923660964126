import React, { useEffect, useState, Component } from 'react'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Table } from 'react-bootstrap';
import LogoBanner from "../components/logobanner.png"

const WithdrawalStrategies = () => { 

// this is in order to control text size depending on screen size
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	const data = [
		{ method: 'Constant $', start: 35, calibration:'', averageValue: 35, medianEndValue: 35, decile10: 35, decile90: 35, endMedianPortfolioValue: 1670},
		{ method: 'Fixed percentage withdrawal', start: 50, calibration:'', averageValue: 53, medianEndValue: 48, decile10: 23, decile90: 86, endMedianPortfolioValue: 941},
		{ method: 'Yale endownment', start: 50, calibration:'70% weight on previous value', averageValue: 53, medianEndValue: 48, decile10: 21, decile90: 90, endMedianPortfolioValue: 904},
		{ method: 'Vanguard dynamic withdrawal', start: 42, calibration:'cap: 5%, floor: 1.5%', averageValue: 52, medianEndValue: 54, decile10: 29, decile90: 97, endMedianPortfolioValue: 973},
		// { method: 'Flex $', start: 43, calibration:'cap: 5%, floor: 1.5%', averageValue: 54, medianEndValue: 80, decile10: 32, decile90: 97, endMedianPortfolioValue: 863},
		{ method: 'Bogleheads variable percentage', start: 50, calibration:'', averageValue: 65, medianEndValue: 73, decile10: 30, decile90: 128, endMedianPortfolioValue: 338},
		{ method: 'Constant $ + bonus', start: 35, calibration:'35k target bonus', averageValue: 59, medianEndValue: 70, decile10: 35, decile90: 70, endMedianPortfolioValue: 338},
	];

	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
				<title>Retire Smart Calc - Observations On Safe Withdrawal Rates From Back-testing And Strategies To Improve</title>
				<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/observations"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />			
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>

        	<div className="row">
			<div className="col-sm-2"></div>
          	<div className="col-sm-8 border border-secondary rounded p-3 mt-2 mb-2">
			{isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
			<h2 className="lead">Withdrawal approaches:</h2>			
			<hr className="my-1"></hr>
			<p className={isSmallScreen ? 'small' : ''}>	
			The simulation calculator allows for different withdrawal approaches to be tested:</p>
			<h6>'Fixed dollar' approaches:</h6>
			<li>Constant $: specified withdrawal amount in real terms (i.e. the level is indexed with inflation each year) taken each year irrespective of market performance.</li>
			<br></br>
			<h6>'Percentage of portfolio' approaches:</h6>
			<li>Fixed percentage withdrawal: withdrawal amount is a set percentage of the portfolio value each year - as such the portfolio can not be exhausted but year to year withdrawal levels can be (very) volatile. The calculator adds the option to add a minimum withdrawal level (in real terms).</li>	
			<li>Yale endowment: withdrawal amount each year is based on formula = x * previous year's withdrawal + (1 - x) * set percentage of the portfolio value that year, where x is a fixed weight. This approach is used by Yale University to manage withdrawals from their endownment fund.  Depending on the weighting of x, it provides more short-term withdrawal level stability than the fixed percentage withdrawal approach.</li>
			<br></br>
			<h6>'Dynamic withdrawal' approches:</h6>
			<li>Vanguard dynamic spending: withdrawal amount based on a set percentage of portfolio value but with a cap and a floor on the maximum real-term change from the previous year's withdrawal level.  Vanguard suggests a cap of 5% and a floor of 1.5% but this can be changed by the investor in the calculator. Futher explanation can be found <a className="text-info" href="https://investor.vanguard.com/investor-resources-education/article/retirement-withdrawal-strategies" target="_blank">here</a>.</li>
			<li>Bogleheads variable percentage withdrawal: withdrawal amount based on a look-up table of percentage withdrawal rates adjusted for age and asset mix. Each year the percentage withdrawal rate in the table increases. The table and further explanation can be found <a className="text-info" href="https://www.bogleheads.org/wiki/Variable_percentage_withdrawal" target="_blank">here</a>.</li>
			<li>Constant $ with bonus (dynamic SWR): two components - the same 'fixed' withdrawal component as the constant approach and a 'bonus' withdrawal component that is taken as and if the portfolio value grows above the safe funding level. The safe funding level is the portfolio size required to meet the income requirements in every back-test simulation cycle. The simulator calculates a safe funding level for each year through the simulation. As each year passes (or for each year the simulation gets shorter) the safe funding level declines. </li>
			{/* <li>Flex $ (dynamic SWR): withdrawal amount each year is flexed up or down (in real terms) by user defined percentages in response to how the portfolio value develops.  It uses the same safe funding level concept as above. If the portfolio value grows above the safe funding level, the withdrawal level flexs up.  Vice versa, if the portfolio value falls below the safe funding level, the withdrawal level flexes down.</li>			 */}
			<br></br>
			<h6>Comparison:</h6>			
			<p>The different approaches can be expected to have different trade-offs in terms of initial withdrawal rate, average withdrawal amount over the simulation period, dispertion of withdrawal amount over the simulation period and the expected portfolio left unspent at the end of the simulation. The table below shows the results across these dimensions for a $1MM portfolio invested in an 60% US equity, 40% US conventional government bond portfolio (with annual rebalancing) for 30 years. Each strategy is backtested against historic data from 1870-2023 with a zero failure rate.</p>
			<Table striped bordered hover variant="dark">
			<thead>
				<tr>
				<th>Approach</th>
				<th>Calibration</th>
				<th>Initial withdrawal ($k)</th>
				<th>Average withdrawal ($k)</th>
				<th>Median last withdrawal ($k)</th>
				<th>Lowest (10th percentile) withdrawal ($k)</th>
				<th>Highest (90th percentile) withdrawal ($k)</th>
				<th>End median portfolio value ($k)</th>
				</tr>
			</thead>
			<tbody>
				{data.map((row, index) => (
				<tr key={index}>
					<td>{row.method}</td>
					<td>{row.calibration}</td>
					<td>{row.start}</td>
					<td>{row.averageValue}</td>
					<td>{row.medianEndValue}</td>
					<td>{row.decile10}</td>
					<td>{row.decile90}</td>
					<td>{row.endMedianPortfolioValue}</td>
				</tr>
				))}
			</tbody>
			</Table>
			</div> 
			<div className="col-sm-2"></div>
		</div>
		</Container>
	); 
	}; 

	export default WithdrawalStrategies;
	 
	  
	  
	  
	  