import { useEffect, useState } from 'react';
// import { setUser } from '../utils/auth';
import useSetUser from '../utils/setuser';


const MainWrapper = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const { setUser } = useSetUser();

    useEffect(() => {
        const handler = async () => {
            setLoading(true);
            await setUser();
            setLoading(false);
        };
        handler();
    }, []);

    return <>{loading ? null : children}</>;
};

export default MainWrapper;
