import axios from 'axios';
import { useAuthStore } from '../store/auth';
import { getRefreshToken, isAccessTokenExpired, setAuthUserToStore, logoutwithoutreset } from './auth';
import { API_BASE_URL } from './constants';
import Cookies from 'js-cookie';

// Accept a prop to trigger the modal
const useAxios = () => {
    const { setAccessToken } = useAuthStore();
    const accessToken = useAuthStore((state) => state.getAccessToken());

    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: { 
            Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,  // Ensure cookies are sent with requests
    });

    axiosInstance.interceptors.request.use(async (req) => {
        // Check if the access token is expired
        if (!isAccessTokenExpired(accessToken)) return req;
        try {
            // Attempt to refresh the access token
            const response = await getRefreshToken();
            // Update access token in store
            setAccessToken(response.access);
            setAuthUserToStore(response.access);
            // Update Authorization header with the new access token
            req.headers.Authorization = `Bearer ${response.access}`;
        } catch (error) {
            return Promise.reject(error);  // Reject the request to avoid further execution
        }

        return req;
    }, (error) => {
        return Promise.reject(error);
    });

    return axiosInstance;
};

export default useAxios;
