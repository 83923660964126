import axios from 'axios';

function useReset() {
    const reset = async (email) => {
        try {
            // 1. Initial GET request to obtain the CSRF token
            const getResponse = await axios.get('/api/password-reset/');
            
            // Check if the CSRF token is present in cookies after GET request
            const csrfToken = document.cookie.match(/csrftoken=([^;]*)/)?.[1];
            
            if (!csrfToken) {
                console.log('CSRF token not found');
                return { error: 'CSRF token not found' };
            }

            console.log('CSRF Token from GET request:', csrfToken); // Log CSRF token

            // 2. POST request with the CSRF token in the headers and email in the body (the corresponding backend View is in FormView so data needs to be sent in Form format)
            const formData = new FormData();
            formData.append('email', email); // Add email as form data

            const postResponse = await axios.post('/api/password-reset/', 
                formData, 
                {
                    headers: {
                        'X-CSRFToken': csrfToken, // Attach the CSRF token in the headers
                        'Content-Type': 'multipart/form-data' // Ensure form data is being sent
                    }
                }
            );

            // Log the response to verify if the email was sent successfully
            console.log('Password reset POST response:', postResponse);

            if (postResponse.status === 200) {
                console.log('Password reset request sent');
                return { error: null }; // Successful request, no errors
            }
        } catch (error) {
            // Log the error and response details for further debugging
            console.log('Error:', error);
            console.log('Error Response:', error.response);

            const errorMsg = error.response?.data?.detail || 'Something went wrong';
            return { error: errorMsg };
        }
    };

    return { reset };
}

export default useReset;